import { gql } from "@apollo/client"

export const FIRM_FRAGMENT = gql`
  fragment FirmAttributes on Firm {
    id
    name
    profileInput
    firmTypeId
    rcgFirmId
    firmType {
      id
      name
    }
    size
    sizeSymbol
    url
  }
`

export const INVESTMENT_FRAGMENT = gql`
  fragment InvestmentAttributes on Submitted {
    id
    firmId
    userId
    email
    sourceId
    source {
      id
      name
    }
    userInput
    createdAt
    updatedAt
    name
  }
`

export const GET_ALL_FIRMS = gql`
  ${FIRM_FRAGMENT}
  ${INVESTMENT_FRAGMENT}
  query GetListFirm(
    $page: PageInput
    $sort: [FirmSort!]
    $firmFilter: FirmFilter
    $submissionFilter: SubmittedFilter
  ) {
    firmList(page: $page, sort: $sort, filter: $firmFilter) {
      items {
        id
        organizationId
        organization {
          id
          name
          allowMultiFirm
          serviceProviderFirms {
            ...FirmAttributes
            submissionList(
              sort: [{ field: name, order: ASC }]
              filter: $submissionFilter
            ) {
              items {
                ...InvestmentAttributes
              }
              total
            }
          }
          primaryFirm {
            ...FirmAttributes
            submissionList(
              sort: [{ field: name, order: ASC }]
              filter: $submissionFilter
            ) {
              items {
                ...InvestmentAttributes
              }
              total
            }
          }
        }
      }
      total
      nextPage {
        offset
        limit
        hasMore
      }
    }
  }
`
