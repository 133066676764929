import React from "react"
import Typography from "@material-ui/core/Typography"
//import { makeStyles } from "@material-ui/styles"

// const useStyles = makeStyles({
//   root: {},
// })

const DescriptionField = ({ description }: any) => {
  //const classes = useStyles()

  if (description) {
    return (
      <Typography variant='subtitle2'>
        <span
          className='description-field'
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Typography>
    )
  }

  return null
}

export default DescriptionField
