import { InMemoryCache } from "@apollo/client"

const cache = new InMemoryCache({
  typePolicies: {
    Users: {
      keyFields: ["email"],
    },
    Query: {
      fields: {
        firmList: {
          keyArgs: false,
        },
        submittedList: {
          keyArgs: false,
        },
      },
    },
  },
})

export default cache
