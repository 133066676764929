import React from "react"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/styles"

import { ObjectFieldTemplateProps } from "@rjsf/core"
//import { utils } from "@rjsf/core"

//import AddButton from "@rjsf/material-ui/dist/AddButton"

//const { canExpand } = utils

const useStyles = makeStyles({
  root: {
    marginTop: 10,
  },
})

const PriorDealObjectTemplate = ({
  DescriptionField,
  description,
  TitleField,
  title,
  properties,
  required,
  disabled,
  readonly,
  uiSchema,
  idSchema,
  schema,
  formData,
  onAddClick,
}: ObjectFieldTemplateProps) => {
  const classes = useStyles()

  if (!properties) {
    console.log("No properties for:", schema)
    return null
  }

  // Partition properties into grids of specified lengths
  // e.g. [3, 3, 6]
  const uiOptions = uiSchema["ui:options"] || {}
  let gridLengths: any = uiOptions.gridLengths

  if (!gridLengths) {
    gridLengths = [properties.length]
  }

  let grids: any[] = []
  let propIndex = 0
  gridLengths.forEach((gridLength: number) => {
    // Slice segment of properties that fall within gridLength
    const end = Math.min(properties.length, propIndex + gridLength)
    const gridProperties = properties.slice(propIndex, end)
    grids.push(gridProperties)
    propIndex += gridLength
  })

  return (
    <div className='prior-deal-object-template'>
      {(uiSchema["ui:title"] || title) && (
        <TitleField
          id={`${idSchema.$id}-title`}
          title={title}
          required={required}
        />
      )}
      {description && (
        <DescriptionField
          id={`${idSchema.$id}-description`}
          description={description}
        />
      )}
      {grids.map((grid, ix) => (
        <Grid key={ix} container={true} spacing={1} className={classes.root}>
          {grid.map((element: any, index: number) => (
            <Grid key={index} item xs>
              {element.content}
            </Grid>
          ))}
        </Grid>
      ))}
    </div>
  )
}

export default PriorDealObjectTemplate
