import React from "react"
import { Divider, Typography } from "@material-ui/core"
import { Switch, Route, useRouteMatch } from "react-router-dom"
import { AdminNavbar } from "../components/Navbar"
import CreateFirmPage from "./CreateFirmPage"
import AssetForm from "../components/AssetForm"

import Board from "./Board"
import FirmProfile from "../components/FirmProfile"
import AssetSelection from "../components/AssetSelection"

export const AdminDashboard = () => {
  const { path } = useRouteMatch()

  return (
    <div className='admin-dashboard'>
      <AdminNavbar />
      <Switch>
        <Route path='/firm/:firmId'>
          <FirmProfile />
        </Route>
        <Route path='/form/:formId'>
          <AssetForm />
        </Route>
        <Route path='/fill-form/:firmId'>
          <AssetSelection />
        </Route>
        <Route path='/create-firm'>
          <CreateFirmPage />
        </Route>
        <Route exact path={path}>
          <Typography variant='h5' component='h5' gutterBottom>
            Admin Dashboard
          </Typography>
          <Divider />
          <Board />
        </Route>
      </Switch>
    </div>
  )
}

export default AdminDashboard
