import React from "react"

import FormLabel from "@material-ui/core/FormLabel"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

import { WidgetProps } from "@rjsf/core"
import { toHash } from '../../utils/toHash';


const CustomCheckboxesWidget = ({
  schema,
  label,
  id,
  disabled,
  options,
  value,
  autofocus,
  readonly,
  required,
  onChange,
  onBlur,
  onFocus,
}: WidgetProps) => {

  const { enumOptions, enumDisabled, inline } = options;

  const _onChange = ({
    target: { checked, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if(checked){
      onChange(value ? [ ...value, name ] : [ name ]);
    } else if (!value) {
      // shouldn't happen, but lets be safe
      return;
    } else {
      const newValue = (value as string[]).filter(x => x !== name);
      onChange(newValue.length ? newValue : undefined);
    }
  }

  const _onBlur = ({
    target: { value },
  }: React.FocusEvent<HTMLButtonElement>) => onBlur(id, value)
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLButtonElement>) => onFocus(id, value)

  const valueLookup = toHash(value);

  return (
    <>
      <FormLabel required={required} htmlFor={id}>
        {label || schema.title}
      </FormLabel>
      <FormGroup row={!!inline}>
        {(enumOptions as any).map((option: any, index: number) => {
          const checked = !!valueLookup[ option.value ];

          const itemDisabled =
            enumDisabled && (enumDisabled as any).indexOf(option.value) !== -1
          const checkbox = (
            <Checkbox
              id={`${id}_${index}`}
              checked={checked}
              disabled={disabled || itemDisabled || readonly}
              autoFocus={autofocus && index === 0}
              onChange={_onChange}
              onBlur={_onBlur}
              onFocus={_onFocus}
              name={option.value}
            />
          )
          return (
            <FormControlLabel
              control={checkbox}
              key={index}
              label={option.label}
            />
          )
        })}
      </FormGroup>
    </>
  )
}

export default CustomCheckboxesWidget
