import { createMuiTheme } from "@material-ui/core/styles"
import MontserratTTF from "./assets/fonts/Montserrat-Regular.ttf"

const Montserrat = {
  // this object is the same as a fontface declaration.
  fontFamily: "Montserrat",
  fontStyle: "normal",
  src: `
    local('Montserrat')
    url(${MontserratTTF}) format('ttf')
  `,
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0d4f94",
    },
    // error: {},
    // warning: {},
    // info: {},
    // success: {},
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    h4: {
      fontSize: "1.4rem",
    },
    h5: {
      fontSize: "1.2rem",
    },
    caption: {
      fontSize: "0.875rem",
      fontStyle: "italic",
    },
    subtitle2: {
      fontWeight: "normal",
      fontStyle: "italic",
      color: "rgb(0, 0, 0, 0.54)",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [Montserrat],
        body: {
          backgroundColor: "#eee",
          color: "#333",
        },
        a: {
          textDecoration: "none",
        },
      },
    },
    MuiFormControl: {
      root: {
        position: "inherit",
      },
    },
    MuiInputLabel: {
      root: {
        position: "inherit",
      },
      animated: {
        position: "inherit",
      },
      shrink: {
        transform: "inherit",
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgb(0, 0, 0, 0.54)",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "0",
      },
    },
    MuiInputBase: {
      root: {
        color: "#000",
      },
    },
    MuiTableCell: {
      root: {
        padding: "8px",
      },
    },
  },
})

/*
MuiFormLabel: {
      root: {
        color: "#000",
      },
    },
*/

export default theme
