import React from "react"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"

type TitleFieldProps = {
  title?: string
}

// https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/TitleField/TitleField.tsx

const TitleField = ({ title }: TitleFieldProps) => (
  <>
    <Box mb={1} mt={1}>
      <Typography variant='h5'>{title}</Typography>
      <Divider />
    </Box>
  </>
)

export default TitleField
