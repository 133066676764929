import { gql } from "@apollo/client"
export const CURRENT_USER = gql`
  query CurrentUserWithOrg {
    currentUser {
      id
      organizationId
      firstName
      lastName
      email
      phoneNumber
      streetAddress1
      streetAddress2
      organization {
        id
        allowMultiFirm
        primaryFirm {
          id
          rcgFirmId
        }
        serviceProviderFirms {
          id
        }
      }
      city
      state
      zipCode
      country
      createdAt
      updatedAt
      deletedAt
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      id
      organizationId
      firstName
      lastName
      email
      phoneNumber
      streetAddress1
      streetAddress2
      city
      state
      zipCode
      country
      createdAt
      updatedAt
      deletedAt
    }
  }
`
