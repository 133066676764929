import React from "react"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
}))

const PageDataPolicy = () => {
  const classes = useStyles()

  return (
    <div className={`page-data-policy ${classes.root}`}>
      <Container>
        <h2>Data Use Policy</h2>
        <p>
          By entering our Portal, you acknowledge your agreement with and
          understanding of the following Terms of Use pertaining to this Portal
          (the "Portal") including all material on it.
        </p>
        <p>
          The information and documents (“Data”) submitted and uploaded and
          stored in the database may be accessed and used by The Objective Data
          Collective (ODC), RockCreek, its clients, prospective clients,
          affiliates, third parties and subscribers ("Users").
        </p>
        <p>
          Unless you have indicated use restrictions, the Data you submit will
          be available for unrestricted use by the Users. It is the manager’s
          responsibility to indicate if the use and distribution of parts of the
          Data should be restricted.
        </p>
        <p>
          The Manager grants ODC perpetual right to use, reproduce, publish,
          distribute, copy, display and create derivative works from any Data
          submitted on the Portal.
        </p>
        <p>
          Users of the database agree to keep the information confidential and
          will be accredited investors and qualified purchasers.
        </p>
        <p>
          We reserve the right to edit/update any portion of your submission as
          appropriate.
        </p>
        <p>
          You warrant the accuracy and truthfulness of all information provided
          by you and that you are authorized to provide such information.
        </p>
        <p>
          You may not modify, distribute, prepare derivative works of, reverse
          engineer, reverse assemble, disassemble, decompile or otherwise
          attempt to decipher any code used in connection with the Portal and/or
          any other aspect of the technology. You shall not market, offer to
          sell, sell and/or otherwise resell the Portal to any third party.
        </p>
        <p>
          We may add to, change or remove any part of these Terms of Use at any
          time, without notice. Any changes to these Terms of Use or any terms
          posted on this Portal apply as soon as they are posted. By continuing
          to use this Portal after any changes are posted, you are indicating
          your acceptance of those changes. We may add, change, discontinue,
          remove or suspend any other content posted on this Portal, at any
          time, without notice and without liability.
        </p>
        <p>
          The Portal is for information purposes only and does not constitute
          and should not be construed as a solicitation or offer or
          recommendation to acquire or dispose of any investment.
        </p>
        <p>
          You are providing your submission with the understanding that there is
          no broker or placement agent relationship arising from your inclusion
          in the database and that there is no fee sharing, profit sharing,
          partnership or joint venture created by virtue of its submission.
          Users of the database agree to keep the information confidential and
          will be accredited investors and qualified purchasers as those terms
          are defined by the United States Securities and Exchange Commission.
        </p>
        <p>
          Technology and format of Portal have been developed and arranged by
          ODC and comprises intellectual property rights belonging to ODC, which
          may not be infringed upon.
        </p>
        <p>
          Use of the Portal is subject to the Terms of Use and all applicable
          laws, including Privacy Laws applicable with regard to Data submitted
          related to personal information subject to privacy laws.
        </p>
        <h2>Privacy Contact Information</h2>
        <p>
          Should there be any questions about the Portal, please contact us at:{" "}
          <a href='mailto:information@therockcreekgroup.com'>
            information@therockcreekgroup.com
          </a>
        </p>
      </Container>
    </div>
  )
}

export default PageDataPolicy
