import React from "react"
import { AppBar, Toolbar } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import logo from "../../assets/images/theodc.png"
import { MaintenanceModeBanner } from "../MaintenanceMode"

// Pre-authenticated navbar with no links

const useStyles = makeStyles(() => ({
  logo: {
    pointerEvents: "none",
  },
  bar: {
    marginBottom: "1em",
    backgroundColor: "hsla(211deg,84%,32%,1)",
  },
}))

export const SimpleNavbar = () => {
  const classes = useStyles()

  return (
    <div className='simple-navbar'>
      <AppBar position='static' className={classes.bar}>
        <Toolbar>
          <img src={logo} className='App-logo' alt='logo' />
        </Toolbar>
      </AppBar>
      <MaintenanceModeBanner />
    </div>
  )
}

export default SimpleNavbar
