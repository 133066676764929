import React, { useContext, useEffect, useMemo } from "react"
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom"
import { Divider, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined"
import { Navbar } from "../components/Navbar"
import Profile from "../components/Profile"
import FirmProfile from "../components/FirmProfile"
import AssetSelection from "../components/AssetSelection"
import UserForms from "../components/UserForms"
import AssetForm from "../components/AssetForm"
import { MainContext } from "../context/MainContext"
import Spinner from "../components/Spinner"
import { AuthContext } from "../context/AuthContext"
import { toast } from "react-toastify"
import { alertColors } from "../Toast"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  spinner: {
    height: "100vh",
  },
  divider: {
    margin: "10px 0",
  },
  intro: {
    "& p:first-child": {
      marginTop: 0,
    },
    "& p:last-child": {
      marginBotom: 0,
    },
    "& ul": {
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
  },
  topRail: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0",
  },
}))

const TOAST_MESSAGE = (
  <div className='alert-body'>
    <ErrorOutlineOutlinedIcon style={{ color: alertColors.warning }} />
    <p>
      The organization you are registered to requires additional setup, please
      contact Portal Support at{" "}
      <a href="mailto:'portal@therockcreekgroup.com'">
        portal@therockcreekgroup.com
      </a>{" "}
      for assistance
    </p>
  </div>
)

export const Dashboard = () => {
  const classes = useStyles()
  const { currentUser, isLoading, isValidUser } = useContext(MainContext)
  const [{ isLoading: isAuthLoading, isCognitoInitialized }] = useContext(
    AuthContext,
  )

  useEffect(() => {
    if (currentUser?.organization?.deletedAt) {
      toast(TOAST_MESSAGE, { autoClose: false, toastId: "check-deleted-org" })
    }
  }, [currentUser?.organization?.deletedAt])

  const stillLoading = useMemo(() => {
    return !!(isLoading || isAuthLoading || !isCognitoInitialized)
  }, [isLoading, isAuthLoading, isCognitoInitialized])

  const { path } = useRouteMatch()
  const hasFirms =
    !!currentUser?.organization &&
    (!!currentUser.organization.primaryFirm ||
      currentUser.organization.serviceProviderFirms.length > 0)

  if (stillLoading) {
    return (
      <div data-cy='dashboard' className='dashboard'>
        <Spinner className={classes.spinner} />
      </div>
    )
  }

  return (
    <div data-cy='dashboard' className='dashboard'>
      <>
        <Navbar />
        <Switch>
          {hasFirms && (
            <Route path='/firm/:firmId'>
              <FirmProfile />
            </Route>
          )}
          {hasFirms && (
            <Route path='/form/:formId'>
              <AssetForm />
            </Route>
          )}
          {hasFirms && (
            <Route path='/fill-form/:firmId'>
              <AssetSelection />
            </Route>
          )}
          <Route path='/profile'>
            <Profile />
          </Route>
          <Route exact path={path}>
            <Typography variant='h4' component='h4' gutterBottom>
              Dashboard
            </Typography>
            Welcome to our data submission portal. Please provide as much
            information as possible on both the firm and the firm's investment
            products that you represent.
            <Divider className={classes.divider} />
            {isValidUser && <UserForms />}
          </Route>
          <Redirect to='/' />
        </Switch>
      </>
    </div>
  )
}

export default Dashboard
