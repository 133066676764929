import React, { Component } from "react"
//import React, { useEffect, useRef, createRef } from "react"

const onKeyDown = (event: any) => {
  // Prevent Enter press from activating submit action
  if (event.keyCode === 13) {
    const target: any = event.target
    event.preventDefault()
    target.click()
    /* // For some reason I was doing both (I forgot why) but either target.click() or the below seem to work now
        // If you actually enable both, it could be problematic (like if you press enter on the "add" button for an array-type field, 2 new array items will appear
        target.dispatchEvent(new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1,
        }));
        */
  }
}

// It would be preferable to use a hook-based component, but it throws this error:
//Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?
// ... ergo this will be a "classic" React Component until time exists to debug further

// export const FormWithoutEnter = (props: any) => {
//   const myRef = useRef<HTMLFormElement>(null)

//   useEffect(() => {
//     if (myRef && myRef.current) {
//       const currentForm = myRef.current
//       console.log(myRef.current.parentNode)
//       currentForm.addEventListener("keydown", onKeyDown)

//       // Cleanup on "componenWillUnmount"
//       return () => {
//         currentForm.removeEventListener("keydown", onKeyDown)
//       }
//     }
//   }, [myRef])

//   return (
//     <form className='form-without-enter' ref={myRef}>
//       {props.children}
//     </form>
//   )
// }

// export default FormWithoutEnter

export class FormWithoutEnter extends Component {
  myRef: HTMLFormElement | null = null

  componentDidMount() {
    const myRef = this.myRef
    if (myRef) {
      myRef.addEventListener("keydown", onKeyDown)
    }
  }

  componentWillUnmount() {
    const myRef = this.myRef
    if (myRef) {
      myRef.removeEventListener("keydown", onKeyDown)
    }
  }

  render() {
    return (
      <form
        {...this.props}
        className='form-without-enter rjsf'
        ref={(element: HTMLFormElement) => {
          this.myRef = element
        }}
      />
    )
  }
}

export default FormWithoutEnter
