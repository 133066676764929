import { UiSchema } from "@rjsf/core"
import { cloneDeep } from "lodash"
import CustomFileField from "../inputs/CustomFileField"
import CustomTextAreaWidget from "../inputs/TextAreaWidget"
import CustomSelectWidget from "../inputs/SelectWidget"
import NumberWidget from "../inputs/NumberWidget"
import CustomCheckboxesWidget from "../inputs/CheckboxesWidget"
import AUMTable, { FirmAUMTable } from "../inputs/AUM"
import ValuationsTable from "../inputs/AUM/ValuationsTable"
import PerformanceGrid from "../inputs/grids/PerformanceGrid"
import SectionHeader from "../inputs/SectionHeader"
import MonthPicker from "../inputs/MonthPicker"
import ObjectInlineFieldTemplate from "../inputs/ObjectTemplates/ObjectInlineFieldTemplate"
import PriorDealObjectTemplate from "../inputs/ObjectTemplates/PriorDealObjectTemplate"
import ImpactTags from "../inputs/ImpactTags"
import ArrayInlineFieldTemplate from "../inputs/ArrayInlineFieldTemplate"
import ExposureGrid from "../inputs/grids/ExposureGrid"
import ExposureGridSet from "../inputs/grids/ExposureGridSet"
import DEIComposition from "../inputs/grids/dei/DEIComposition"
import XlsGrid from "../inputs/grids/XlsGrid"
//import XlsArrayGrid from "../inputs/grids/XlsArrayGrid"
import FormTemplate from "./FormTemplate"
import { default as definitions } from "schema-validation/schema/definitions.json"
import { default as firmProfileSchema } from "schema-validation/schema/firm-profile-schema.json"
import { default as firmProfileRequired } from "schema-validation/schema/firm-profile-required.json"
import { default as firmProfileUiSchema } from "../schema/firm-profile-ui-schema.json"
import { default as longOnlyEquitySchema } from "schema-validation/schema/long-only-equity-schema.json"
import { default as longOnlyEquityRequired } from "schema-validation/schema/long-only-equity-required.json"
import { default as longOnlyEquityUiSchema } from "../schema/long-only-equity-ui-schema.json"
import { default as longOnlyFixedIncomeSchema } from "schema-validation/schema/long-only-fixed-income-schema.json"
import { default as longOnlyFixedIncomeRequired } from "schema-validation/schema/long-only-fixed-income-required.json"
import { default as longOnlyFixedIncomeUiSchema } from "../schema/long-only-fixed-income-ui-schema.json"
import { default as hedgeFundSchema } from "schema-validation/schema/hedge-fund-schema.json"
import { default as hedgeFundRequired } from "schema-validation/schema/hedge-fund-required.json"
import { default as hedgeFundUiSchema } from "../schema/hedge-fund-ui-schema.json"
import { default as privateEquitySchema } from "schema-validation/schema/private-equity-schema.json"
import { default as privateEquityRequired } from "schema-validation/schema/private-equity-required.json"
import { default as privateEquityUiSchema } from "../schema/private-equity-ui-schema.json"
import { default as ventureCapitalSchema } from "schema-validation/schema/venture-capital-schema.json"
import { default as ventureCapitalRequired } from "schema-validation/schema/venture-capital-required.json"
import { default as ventureCapitalUiSchema } from "../schema/venture-capital-ui-schema.json"
import { default as privateCreditySchema } from "schema-validation/schema/private-credit-schema.json"
import { default as privateCredityRequired } from "schema-validation/schema/private-credit-required.json"
import { default as privateCredityUiSchema } from "../schema/private-credit-ui-schema.json"
import { default as realEstateSchema } from "schema-validation/schema/real-estate-schema.json"
import { default as realEstateRequired } from "schema-validation/schema/real-estate-required.json"
import { default as realEstateUiSchema } from "../schema/real-estate-ui-schema.json"
import { default as naturalResourcesSchema } from "schema-validation/schema/natural-resources-schema.json"
import { default as naturalResourcesRequired } from "schema-validation/schema/natural-resources-required.json"
import { default as naturalResourcesUiSchema } from "../schema/natural-resources-ui-schema.json"
import { default as infrastructureSchema } from "schema-validation/schema/infrastructure-schema.json"
import { default as infrastructureRequired } from "schema-validation/schema/infrastructure-required.json"
import { default as infrastructureUiSchema } from "../schema/infrastructure-ui-schema.json"
import { default as companySchema } from "schema-validation/schema/company-schema.json"
import { default as companyRequired } from "schema-validation/schema/company-required.json"
import { default as companyUiSchema } from "../schema/company-ui-schema.json"
import { JSONSchema7 } from "json-schema"

type SchemaResponse = {
  schema: any
  uiSchema: UiSchema
}

const schemaMap: any = {
  [FormTemplate.Firm]: firmProfileSchema,
  [FormTemplate.LongOnlyEquity]: longOnlyEquitySchema,
  [FormTemplate.LongOnlyFixedIncome]: longOnlyFixedIncomeSchema,
  [FormTemplate.HedgeFund]: hedgeFundSchema,
  [FormTemplate.PrivateEquity]: privateEquitySchema,
  [FormTemplate.VentureCapital]: ventureCapitalSchema,
  [FormTemplate.PrivateCredit]: privateCreditySchema,
  [FormTemplate.RealEstate]: realEstateSchema,
  [FormTemplate.NaturalResources]: naturalResourcesSchema,
  [FormTemplate.Infrastructure]: infrastructureSchema,
  [FormTemplate.Company]: companySchema,
}

const requiredMap: any = {
  [FormTemplate.Firm]: firmProfileRequired,
  [FormTemplate.LongOnlyEquity]: longOnlyEquityRequired,
  [FormTemplate.LongOnlyFixedIncome]: longOnlyFixedIncomeRequired,
  [FormTemplate.HedgeFund]: hedgeFundRequired,
  [FormTemplate.PrivateEquity]: privateEquityRequired,
  [FormTemplate.VentureCapital]: ventureCapitalRequired,
  [FormTemplate.PrivateCredit]: privateCredityRequired,
  [FormTemplate.RealEstate]: realEstateRequired,
  [FormTemplate.NaturalResources]: naturalResourcesRequired,
  [FormTemplate.Infrastructure]: infrastructureRequired,
  [FormTemplate.Company]: companyRequired,
}

const uiSchemaMap: any = {
  [FormTemplate.Firm]: firmProfileUiSchema,
  [FormTemplate.LongOnlyEquity]: longOnlyEquityUiSchema,
  [FormTemplate.LongOnlyFixedIncome]: longOnlyFixedIncomeUiSchema,
  [FormTemplate.HedgeFund]: hedgeFundUiSchema,
  [FormTemplate.PrivateEquity]: privateEquityUiSchema,
  [FormTemplate.VentureCapital]: ventureCapitalUiSchema,
  [FormTemplate.PrivateCredit]: privateCredityUiSchema,
  [FormTemplate.RealEstate]: realEstateUiSchema,
  [FormTemplate.NaturalResources]: naturalResourcesUiSchema,
  [FormTemplate.Infrastructure]: infrastructureUiSchema,
  [FormTemplate.Company]: companyUiSchema,
}

const widgets: any = {
  CustomTextAreaWidget: CustomTextAreaWidget,
  CustomSelectWidget: CustomSelectWidget,
  MonthPicker: MonthPicker,
  NumberWidget: NumberWidget,
  CustomCheckboxesWidget: CustomCheckboxesWidget,
}

const fields: any = {
  AUMTable: AUMTable,
  AssetAUMTable: AUMTable,
  FirmAUMTable: FirmAUMTable,
  PerformanceGrid: PerformanceGrid,
  SectionHeader: SectionHeader,
  ObjectInlineFieldTemplate: ObjectInlineFieldTemplate,
  ExposureGrid: ExposureGrid,
  DEIComposition: DEIComposition,
  XlsGrid: XlsGrid,
  ExposureGridSet: ExposureGridSet,
  ValuationsTable: ValuationsTable,
  // XlsArrayGrid: XlsArrayGrid,
  ImpactTags: ImpactTags,
  CustomFileField: CustomFileField,
}

const templates: any = {
  ObjectInlineFieldTemplate: ObjectInlineFieldTemplate,
  PriorDealObjectTemplate: PriorDealObjectTemplate,
  ArrayInlineFieldTemplate: ArrayInlineFieldTemplate,
}

export const getSchemaResponse = (
  formTemplate: FormTemplate,
  isROAdmin: boolean,
): SchemaResponse => {
  const schema = {
    definitions: definitions as { [key: string]: JSONSchema7 },
    type: "object",
    required: requiredMap[formTemplate],
    properties: schemaMap[formTemplate],
  }

  const uiSchema = createUiSchema(formTemplate, isROAdmin)

  const schemaResponse = {
    schema,
    uiSchema,
  }
  return schemaResponse
}

const createUiSchema = (
  formTemplate: FormTemplate,
  isROAdmin: boolean,
): UiSchema => {
  let uiSchema: UiSchema = cloneDeep(uiSchemaMap[formTemplate])

  // Recursively traverse all object properties on a given object and...
  // Convert string props in JSON object to "real" JS type
  const convertPropsToTypes = (schemaProps: any) => {
    // If ui:widget exists, convert string to Type
    if (schemaProps.hasOwnProperty("ui:widget")) {
      const widgetName = schemaProps["ui:widget"]
      schemaProps["ui:widget"] = widgets[widgetName]
    }
    // If ui:field exists, convert string to Type
    if (schemaProps.hasOwnProperty("ui:field")) {
      const fieldName = schemaProps["ui:field"]
      schemaProps["ui:field"] = fields[fieldName]
    }
    //If ui:ObjectFieldTemplate exists, convert string to Type
    if (schemaProps.hasOwnProperty("ui:ObjectFieldTemplate")) {
      const name = schemaProps["ui:ObjectFieldTemplate"]
      schemaProps["ui:ObjectFieldTemplate"] = templates[name]
    }
    //If ui:ObjectFieldTemplate exists, convert string to Type
    if (schemaProps.hasOwnProperty("ui:ArrayFieldTemplate")) {
      const name = schemaProps["ui:ArrayFieldTemplate"]
      schemaProps["ui:ArrayFieldTemplate"] = templates[name]
    }

    if (typeof schemaProps === "object" && isROAdmin) {
      schemaProps["ui:disabled"] = true
    }

    // Enumerate object properties and make recursive call
    Object.entries(schemaProps).forEach((entry) => {
      // eslint-disable-next-line
      const [propKey, propValue] = entry
      const isObject = typeof propValue === "object"
      if (isObject) {
        convertPropsToTypes(propValue)
      }
    })
  }

  convertPropsToTypes(uiSchema)

  return uiSchema
}
