import {
  Modal,
  Paper,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core"
import * as types from "graphql-types/generated/portal-client-types"
import React from "react"
import { get } from "lodash"
import { JSONSchema7Object } from "json-schema"

interface ImportUpdatesProps {
  pendingUpdates: types.Submitted
  formData: JSONSchema7Object
  labels: any
  changeSkipUpdatePrompt: (value: React.SetStateAction<number>) => void
  changePendingUpdates: (value: any) => void
  setFormData: React.Dispatch<any>
}

function ImportUpdates({
  pendingUpdates,
  formData,
  labels,
  changePendingUpdates,
  changeSkipUpdatePrompt,
  setFormData,
}: ImportUpdatesProps) {
  if (Object.keys(pendingUpdates).length < 1) return null

  function mergeRecords() {
    const mergedRecords = [
      ...Object.keys(formData),
      ...Object.keys(pendingUpdates.userInput),
    ]
      .filter((v, i, a) => a.indexOf(v) === i)
      .reduce((acc, key) => {
        acc[key] = pendingUpdates.userInput[key] || formData[key]
        return acc
      }, {} as any)

    changeSkipUpdatePrompt(pendingUpdates.updatedAt)
    setFormData(mergedRecords)
    changePendingUpdates({})
  }

  return (
    <Modal
      open={!!Object.keys(pendingUpdates).length}
      aria-labelledby='modal-title'
      aria-describedby='modal-title'
    >
      <Paper className='modal'>
        <p id='modal-title'>
          Another user has updated this form, would you like to import their
          edits?
        </p>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Incoming Entry</TableCell>
              <TableCell>Your Entry (Will be overwritten)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              ...Object.keys(formData),
              ...Object.keys(pendingUpdates.userInput),
            ]
              .filter((val, i, arr) => arr.indexOf(val) === i)
              .filter(
                (key) =>
                  JSON.stringify(formData[key]) !==
                  JSON.stringify(pendingUpdates.userInput[key]),
              )
              .map((key, i) => (
                <TableRow key={`${key}::${i}`}>
                  <TableCell>{get(labels, [key, "title"], "")}</TableCell>
                  <TableCell>
                    {JSON.stringify(pendingUpdates.userInput[key])}
                  </TableCell>
                  <TableCell>{JSON.stringify(formData[key])}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div className='footer'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              mergeRecords()
            }}
          >
            Yes
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              changeSkipUpdatePrompt(pendingUpdates.updatedAt)
              changePendingUpdates({})
            }}
          >
            No
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}

export default ImportUpdates
