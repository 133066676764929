import React from "react"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { currencies } from "../../utils/constants"
import { makeStyles } from "@material-ui/core/styles"

// const { asNumber, guessType } = utils

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  input: {
    flex: "1",
    marginLeft: theme.spacing(1),
  },
}))

type Props = {
  id?: string
  label: string
  value: string
  onChange: (value: any) => void
}

const CurrencySelector = ({ id, label, value, onChange }: Props) => {
  let enumOptions = currencies
  const classes = useStyles()

  //const emptyValue = ""

  const _onChange = ({
    target: { value },
  }: React.ChangeEvent<{ name?: string; value: unknown }>) => onChange(value)

  return (
    <TextField
      id={id}
      label={label}
      select
      value={value}
      onChange={_onChange}
      InputProps={{ className: classes.input }}
      InputLabelProps={{
        shrink: true,
      }}
      className={classes.root}
    >
      {enumOptions.map((value: any, i: number) => {
        return (
          <MenuItem key={i} value={value}>
            {value}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

export default CurrencySelector
