import { CssBaseline } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core/styles"
import { Amplify } from "aws-amplify"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { AuthProvider } from "./context/AuthContext"
import { theme } from "./global.styles"
import "./index.css"
import * as Sentry from "@sentry/react"
import GA from "./utils/GA"
import { getToken } from "./auth"
import PortalApolloProvider from "./PortalApolloProvider"

// Setup Sentry error logging
Sentry.init({
  dsn: (window as any).SENTRY_DSN,
  environment: (window as any).SENTRY_ENVIRONMENT,
  release: (window as any).APP_VERSION,
  beforeSend(event: any) {
    if (
      !["dev", "staging", "production", "test"].includes(
        (window as any).SENTRY_ENVIRONMENT,
      )
    ) {
      return null
    }
    return event
  },
})

// Setup GA
GA.initialize()

Amplify.configure({
  Auth: {
    // mandatorySignId: true, // this requires our users to be signed in before they can interact.
    region: (window as any).POOL_REGION,
    userPoolId: (window as any).POOL_ID,
    userPoolWebClientId: (window as any).POOL_CLIENT_ID,
  },
})

// prettier-ignore
ReactDOM.render(
  <AuthProvider>
    <PortalApolloProvider url={(window as any).GRAPHQL_API} getToken={getToken}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
    </PortalApolloProvider>
  </AuthProvider>
  , document.getElementById('root')
)
