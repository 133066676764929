import React, { useRef } from "react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  styled,
} from "@material-ui/core"
import { AssetClassEnum } from "graphql-types/generated/portal-client-types"

export interface AssetClassSelectProps extends SelectProps {
  data: AssetClassEnum[]
}
const AssetClassSelect: React.FC<AssetClassSelectProps> = (
  props: AssetClassSelectProps,
) => {
  const instanceId = useRef((Math.random() * 100000).toString(36)).current
  const { children, data, label, ...restProps } = props
  const labelId = `asset-class-label-${instanceId}`

  return (
    <FormControl>
      <InputLabel shrink id={labelId}>
        {label}
      </InputLabel>
      <Select data-cy='asset-class-select' labelId={labelId} {...restProps}>
        {data.map((assetClass) => (
          <MenuItem
            data-cy='asset-class-select-item'
            key={assetClass.id as number}
            value={assetClass.id as number}
          >
            {assetClass.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default styled(AssetClassSelect)({
  marginTop: "2em",
  marginBottom: "1em",
})
