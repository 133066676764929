import { Organization, Firm } from "graphql-types/generated/portal-client-types"

export function getRegisteredFirmsFromOrganization(
  organization?: Organization | null,
): Firm[] {
  if (!organization) return []

  return organization.serviceProviderFirms.concat(
    organization.primaryFirm ?? [],
  )
}
