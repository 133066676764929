import { gql } from "@apollo/client"

export const GET_FIRM = gql`
  query GetFirm($firmId: String) {
    firm(firmId: $firmId) {
      id
      organizationId
      profileInput
      firmTypeId
      firmType {
        id
        name
      }
      name
      size
      sizeSymbol
      url
      createdAt
      createdBy
      updatedBy
      updatedAt
    }
  }
`

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      email
      organizationId
    }
  }
`

export const CREATE_FIRM = gql`
  mutation CreateFirm($id: String!, $input: FirmInput!) {
    createFirm(id: $id, input: $input) {
      id
      organizationId
      profileInput
      firmTypeId
      firmType {
        id
        name
      }
      name
      size
      sizeSymbol
      url
      createdAt
      createdBy
      updatedBy
      updatedAt
    }
  }
`

export const UPDATE_FIRM = gql`
  mutation UpdateFirm($id: String!, $input: FirmInput!) {
    updateFirm(id: $id, input: $input) {
      id
      organizationId
      profileInput
      firmTypeId
      firmType {
        id
        name
      }
      name
      size
      sizeSymbol
      url
      createdAt
      createdBy
      updatedBy
      updatedAt
    }
  }
`

export const DELETE_FIRM = gql`
  mutation DeleteFirm($id: String!) {
    deleteFirm(id: $id)
  }
`
