import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { useMemo } from "react"
import cache from "./configureApolloCache"
import React from "react"

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  GraphQLRequest,
} from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"

type Props = {
  url: string
  getToken: (
    request: GraphQLRequest,
    headers: unknown,
  ) => Promise<string | undefined> | string | undefined
  children: JSX.Element | JSX.Element[]
}

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  graphQLErrors?.forEach(({ message, locations, path }) => {
    console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    )
  })
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

export default function ({ url, getToken, children }: Props) {
  const client = useMemo(() => {
    //https://github.com/jaydenseric/apollo-upload-client/issues/221
    const fileUploadLink = (createUploadLink({
      uri: (window as any).GRAPHQL_API,
    }) as unknown) as ApolloLink

    const authMiddleware = setContext(async (request, { headers }) => {
      const token = await getToken(request, headers)
      if (token) {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
            "apollo-require-preflight": "true",
          },
        }
      }
      return {
        headers: {
          ...headers,
          "apollo-require-preflight": "true",
        },
      }
    })

    return new ApolloClient({
      link: ApolloLink.from([onErrorLink, authMiddleware, fileUploadLink]),
      cache,
      resolvers: {},
      connectToDevTools: true,
      defaultOptions: {
        mutate: {
          errorPolicy: "all",
        },
      },
    })
  }, [url, getToken])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
