import React, { useContext } from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { AuthContext } from "../context/AuthContext"

export interface ProtectedRouteProps extends RouteProps {
  component: any
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const [authContextState] = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={(props) => {
        return authContextState.isAuthenticated && authContextState.email ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

export default ProtectedRoute
