import React from "react"
import { FieldProps } from "@rjsf/core"
import { isNaN, merge } from "lodash"
import { ColumnDefinition } from "../grids/ColumnDefinitions"
//import { makeStyles } from "@material-ui/core/styles"
import numeral from "numeral"
import BaseAumTable from "./BaseAumTable"

// Firm-level AUM grid

// const useStyles = makeStyles((theme) => ({
//   grid: {
//     maxWidth: "600px",
//   },
// }))

const columns: ColumnDefinition[] = [
  {
    title: "Year",
    propName: "year",
    readOnly: true,
    width: "100px",
    type: "number",
  },
  {
    title: "Quarter",
    propName: "quarter",
    readOnly: true,
    width: "100px",
    type: "string",
  },
  {
    title: "AUM",
    propName: "firmAum",
    type: "number",
    valueRenderer: (cell) => {
      const value = Number(cell.value)

      return isNaN(value) || !value ? "" : numeral(value).format("0,0")
    },
  },
  {
    title: "Firm Flow",
    propName: "firmFlow",
    type: "number",
    valueRenderer: (cell) => {
      const value = Number(cell.value)
      return isNaN(value) || !value ? "" : numeral(value).format("0,0")
    },
  },
]

const FirmAUMTable = (props: FieldProps) => {
  const uiSchema = merge(props.uiSchema, {
    "ui:options": {
      showCurrency: true,
    },
  })

  return (
    <BaseAumTable
      {...props}
      uiSchema={uiSchema}
      className={`firm-aum`}
      columns={columns}
      maxGridWidth='500px'
    />
  )
}

export default FirmAUMTable
