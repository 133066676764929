import React from "react"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { WidgetProps } from "@rjsf/core"
import { utils } from "@rjsf/core"
import { isBoolean } from "lodash"

const { schemaRequiresTrueValue } = utils

// Largely preserves RJSF component, but checks isBoolean
// https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/CheckboxWidget/CheckboxWidget.tsx

const CheckboxWidget = (props: WidgetProps) => {
  const {
    schema,
    id,
    value,
    disabled,
    readonly,
    label,
    autofocus,
    onChange,
    onBlur,
    onFocus,
  } = props

  // Because an unchecked checkbox will cause html5 validation to fail, only add
  // the "required" attribute if the field value must be "true", due to the
  // "const" or "enum" keywords
  const required = schemaRequiresTrueValue(schema)
  // eslint-disable-next-line
  const _onChange = ({}, checked: boolean) => onChange(checked)
  const _onBlur = ({
    target: { value },
  }: React.FocusEvent<HTMLButtonElement>) => onBlur(id, value)
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLButtonElement>) => onFocus(id, value)

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={id}
          checked={isBoolean(value) ? value : false}
          required={required}
          disabled={disabled || readonly}
          autoFocus={autofocus}
          onChange={_onChange}
          onBlur={_onBlur}
          onFocus={_onFocus}
        />
      }
      label={label}
    />
  )
}

export default CheckboxWidget
