import { gql } from "@apollo/client"

export const SYNC_DB_WITH_COGNITO = gql`
  mutation SyncDBWithCognitoUser($user: UserInput, $organization: FirmInput) {
    syncDBWithCognitoUser(user: $user, organization: $organization) {
      id
      organizationId
      email
      organization {
        id
        name
        allowMultiFirm
        deletedAt
        primaryFirm {
          id
          name
          profileInput
          firmTypeId
          size
          sizeSymbol
          url
          rcgFirmId
        }
        serviceProviderFirms {
          id
          name
          profileInput
          firmTypeId
          size
          sizeSymbol
          url
        }
      }
    }
  }
`
