import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import TableCell from "@material-ui/core/TableCell"

import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import * as types from "graphql-types/generated/portal-client-types"
import { get } from "lodash"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import BoardRow from "./BoardRow"
import { getRegisteredFirmsFromOrganization } from "../context/helpers"

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
})

interface Props {
  organization: types.Organization
  onDeleteFirm(firm: types.Firm): Promise<void>
  onDeleteSubmission(submission: types.Submitted): Promise<void>
}

export const OrganizationBoardRow = ({
  organization,
  onDeleteFirm,
  onDeleteSubmission,
}: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()
  const firms = useMemo(
    () => getRegisteredFirmsFromOrganization(organization),
    [organization],
  )

  return (
    <>
      <TableRow
        key={organization.id}
        data-cy='org-board-row'
        className={`board-row ${classes.root}`}
        hover={true}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton
            data-cy='expand-org board-row'
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row' colSpan={6}>
          <Typography
            data-cy='organization-name'
            variant='h6'
            gutterBottom
            component='span'
          >
            {get(organization, "name", "")}{" "}
            <span className='flag'>Multi-Firm Organization</span>
          </Typography>
        </TableCell>
      </TableRow>
      {open
        ? firms.map((firm) => (
            <BoardRow
              key={firm.id}
              firm={firm}
              indent={true}
              onDeleteFirm={onDeleteFirm}
              onDeleteSubmission={onDeleteSubmission}
            />
          ))
        : null}
    </>
  )
}

export default OrganizationBoardRow
