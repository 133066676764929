import { gql } from "@apollo/client"

export const GET_ASSET_CLASSES = gql`
  query GetAssetClasses {
    assetClassEnumList {
      id
      name
    }
  }
`

export const INSERT_SUBMISSION = gql`
  mutation InsertSubmitted($input: SubmittedInput!) {
    insertSubmitted(input: $input) {
      id
    }
  }
`
