import React from "react"

type Props = {
  className?: string
}

export const Spinner = ({ className }: Props) => {
  return (
    <div data-cy='spinner' className={`spinner ${className}`}>
      <div className='ring'></div>
    </div>
  )
}

export default Spinner
