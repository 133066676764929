import React, { useEffect, useState, useCallback, useMemo } from "react"
import Button from "../../components/Button"
import { FieldProps } from "@rjsf/core"
import { get, set, isEmpty, cloneDeep, isNaN, range, uniq } from "lodash"
import { Box } from "@material-ui/core"
import ReactDataSheet from "react-datasheet"
import "react-datasheet/lib/react-datasheet.css"
import TitleField from "../TitleField"
import DescriptionField from "../DescriptionField"
import { ColumnDefinition } from "../grids/ColumnDefinitions"
import CurrencySelector from "./CurrencySelector"
import { isAumDataEmpty } from "../../utils/isAumDataEmpty"
import { currencies } from "../../utils/constants"

export type AumTableProps = FieldProps & {
  className: string
  columns: ColumnDefinition[]
  maxGridWidth: string
}

function yearOfBlankRows(year: number): AumRow[] {
  return [
    { year, quarter: "Q4" },
    { year, quarter: "Q3" },
    { year, quarter: "Q2" },
    { year, quarter: "Q1" },
  ]
}

function sortRows(row1: AumRow, row2: AumRow): number {
  if (row1.year > row2.year) return -1
  if (row1.year < row2.year) return 1
  if (row1.quarter > row2.quarter) return -1
  if (row1.quarter < row2.quarter) return 1
  return 0
}

const currentYear = new Date().getFullYear()
const defaultAumData: AumData = {
  currency: currencies[0],
  rows: yearOfBlankRows(currentYear),
}

function checkFormatting(sortedRows: AumRow[], startYear: number) {
  const hasCurrentYear = sortedRows[0].year === currentYear
  const yearRange = range(startYear, sortedRows[0].year + 1)
  const hasConsecutiveYears = uniq(sortedRows.map((r) => r.year)) === yearRange

  const hasFourQuartersPerYear = yearRange.every((year) => {
    const yearRows = sortedRows.filter((r) => r.year === year)
    return yearRows.every((r, i) => r.quarter === `Q${4 - i}`)
  })

  return hasCurrentYear && hasConsecutiveYears && hasFourQuartersPerYear
}

const isAumData = (variableToCheck: any): variableToCheck is AumData => {
  return (
    (variableToCheck as AumData).currency !== undefined &&
    (variableToCheck as AumData).rows !== undefined &&
    (variableToCheck as AumData).rows.every(
      (row) => row.hasOwnProperty("year") && row.hasOwnProperty("quarter"),
    )
  )
}

export const BaseAUMTable = (props: AumTableProps) => {
  const {
    schema,
    uiSchema,
    required,
    formData,
    onChange,
    columns,
    className,
    maxGridWidth,
  } = props
  const [grid, setGrid] = useState<any[][] | null>(null)
  const uiOptions = uiSchema["ui:options"] || {}
  const { hideTitle, hideDescription, showCurrency } = uiOptions

  // Gets formData rows if the exist, or default rows
  const aumData: AumData = useMemo(() => {
    if (formData && isAumData(formData)) {
      return formData as AumData
    }
    return defaultAumData
  }, [formData])

  const gridRows = useMemo(() => {
    const sortedRows = aumData.rows.sort(sortRows)
    const startYear = sortedRows.slice(-1)[0].year
    const tableRange = range(startYear, currentYear + 1)
    const valid = checkFormatting(sortedRows, startYear)
    console.log("valid", valid)
    if (valid) return sortedRows
    const grid = tableRange.reduce((accu: AumRow[], year) => {
      const yearData = sortedRows.filter((row) => row.year === year)

      const row = yearOfBlankRows(year).reduce((rows: AumRow[], curr) => {
        return rows.concat([
          {
            ...curr,
            ...yearData.find((r) => r && r.quarter === curr.quarter),
          },
        ])
      }, [])

      return accu.concat(row)
    }, [])

    return grid.sort(sortRows)
  }, [aumData])

  useEffect(() => {
    let nextGrid: any[] = []

    let rows = gridRows

    rows.forEach((row, rowIx) => {
      nextGrid[rowIx] = []
      columns.forEach((col, colIx) => {
        nextGrid[rowIx][colIx] = {
          readOnly: col.readOnly,
          key: col.propName,
          value: get(rows[rowIx], col.propName, ""),
        }
      })
    })

    setGrid(nextGrid)
  }, [schema, formData, columns, gridRows])

  const handleChange = useCallback(
    (key: "currency" | "rows", value: any) => {
      const nextAumData: any = cloneDeep(aumData)
      nextAumData[key] = value

      onChange(nextAumData)
    },
    [onChange, aumData],
  )

  const handleRowsChange = (changes: any) => {
    let nextFormData = cloneDeep(gridRows)

    changes.forEach(({ cell, row, col, value }: any) => {
      const cellValue = getCellValue(columns[col], value)
      set(nextFormData[row], cell.key, cellValue)
    })

    handleChange("rows", nextFormData)
  }

  // Gets cell value, handling type conversion and emptiness checks
  const getCellValue = (column: ColumnDefinition, value: any) => {
    if (isEmpty(value)) {
      return null
    }

    if (column.type === "number") {
      const valueNumbersOnly = value.replace(/[^-0123456789.]/g, "")
      const numericValue = Number(valueNumbersOnly)
      return isNaN(numericValue) ? null : numericValue
    }

    return value
  }

  // Add new year's worth of data rows
  const addYear = useCallback(() => {
    const rows = gridRows

    const lastYear = rows[rows.length - 1].year
    const newYear = lastYear - 1

    const nextRows = [
      ...rows,
      { year: newYear, quarter: "Q4" },
      { year: newYear, quarter: "Q3" },
      { year: newYear, quarter: "Q2" },
      { year: newYear, quarter: "Q1" },
    ]

    handleChange("rows", nextRows)
  }, [handleChange, gridRows])

  // Currency selection
  const handleCurrencyChange = useCallback(
    (value) => {
      handleChange("currency", value)
    },
    [handleChange],
  )

  if (!grid) {
    return null
  }

  return (
    <div className={className}>
      {schema.title && !hideTitle && (
        <TitleField title={`${schema.title}${required ? "\u00a0*" : ""}`} />
      )}
      {schema.description && !hideDescription && (
        <DescriptionField description={schema.description} />
      )}
      <Box style={{ marginTop: "1em", maxWidth: maxGridWidth }}>
        {showCurrency && (
          <CurrencySelector
            label='Currency'
            value={aumData.currency}
            onChange={handleCurrencyChange}
          />
        )}
        <ReactDataSheet
          data={grid}
          // https://github.com/nadbm/react-datasheet/issues/309
          parsePaste={(pastedString) => 
            pastedString.trim().split(/\r?\n/).map(row => row.split('\t'))
          }
          valueRenderer={(cell: any, rowIx, colIx) => {
            const renderer = columns[colIx].valueRenderer
            return renderer ? renderer(cell, rowIx, colIx) : cell.value
          }}
          onCellsChanged={handleRowsChange}
          sheetRenderer={(props: any) => (
            <table className={props.className}>
              <thead>
                <tr>
                  {columns.map((col, ix) => (
                    <th
                      key={ix}
                      className='cell read-only rotate'
                      style={{ width: col.width }}
                    >
                      <div>
                        <span>{col.title}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{props.children}</tbody>
            </table>
          )}
        />
        <Button variant='contained' color='primary' onClick={addYear} fullWidth>
          Add Year
        </Button>
      </Box>
    </div>
  )
}

export default BaseAUMTable
