import ReactGA from "react-ga"
import Cookies from "js-cookie"

// Google analytics utilities

const gaMeasurementId = (window as any).GA_MEASUREMENT_ID

// Sets the GA opt-out value
// https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
const setGADisable = (isDisabled: boolean) => {
  const w = window as any
  w[`ga-disable-${gaMeasurementId}`] = isDisabled
}

export const GA = {
  COOKIE_NAME: "gaApproval",

  initialize: () => {
    // Update global GA disabled setting based on cookie consent
    const hasCookieConsent = Cookies.get(GA.COOKIE_NAME) !== undefined
    setGADisable(!hasCookieConsent)

    ReactGA.initialize(gaMeasurementId)
    ReactGA.set({ anonymizeIp: true })
    ReactGA.pageview(window.location.pathname + window.location.search)
  },

  handleCookieConsent: () => {
    GA.initialize()
  },

  handleCookieDeny: () => {
    setGADisable(true)
  },
}

export default GA
