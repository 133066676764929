// "Enum" of Form ids. Roughly maps to shortform.asset_class

export enum FormTemplate {
  Firm = 0,
  LongOnlyEquity = 1,
  LongOnlyFixedIncome = 2,
  HedgeFund = 3,
  PrivateEquity = 4,
  VentureCapital = 5,
  PrivateCredit = 6,
  RealEstate = 7,
  NaturalResources = 8,
  Infrastructure = 9,
  Company = 10,
}

export default FormTemplate
