import React from "react"
import Spinner from "../Spinner"
import ImpactForm from "../form/ImpactForm"
import { UiSchema, IChangeEvent, ISubmitEvent, ErrorSchema } from "@rjsf/core"
import { JSONSchema7 } from "json-schema"
import * as types from "graphql-types/generated/portal-client-types"

interface FormProps {
  schema: JSONSchema7
  uiSchema: UiSchema
  handleChange: (e: IChangeEvent<any>, es?: ErrorSchema) => any
  handleSubmit: (e: ISubmitEvent<any>) => any
  formData: any
  form: types.Submitted
  formId: string
}

function Form({
  schema,
  uiSchema,
  handleChange,
  handleSubmit,
  formData,
  form,
  formId,
}: FormProps) {
  if (!schema && !formData) return <Spinner />

  return (
    <ImpactForm
      schema={schema}
      uiSchema={uiSchema}
      onChange={handleChange}
      onSubmit={handleSubmit}
      formData={formData}
      idPrefix={`${form.sourceId || "-1"}`}
      submittedId={formId}
      formTemplateId={form.sourceId || 1}
    />
  )
}

export default Form
