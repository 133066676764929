import { Container, Link } from "@material-ui/core"
import ToastAlert from "./Toast"
import React, { useContext, useMemo } from "react"
import * as Sentry from "@sentry/react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Dashboard } from "./pages"
import { MainProvider } from "./context/MainContext"
import AdminDashboard from "./admin/AdminDashboard"
import PasswordForm from "./components/PasswordForm"
import { VersionChecker } from "./components/ApplicationVersionCheck"
import { makeStyles } from "@material-ui/core/styles"
import "./App.css"
import { Login, Signup, AnswerChallenge } from "./components"
import { AuthContext } from "./context/AuthContext"
import { ProtectedRoute } from "./routes"
import PagePrivacy from "./components/legal/PagePrivacy"
import PageDataPolicy from "./components/legal/PageDataPolicy"
import Analytics from "react-router-ga"
import CookieConsentPrompt from "./components/legal/CookieConsentPrompt"
import GA from "./utils/GA"
import Spinner from "./components/Spinner"
import MaintenancePage from "./components/MaintenanceMode"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  snackbarLink: {
    color: "#FFF",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  spinner: {
    height: "100vh",
  },
}))

const App = () => {
  const [authContextState] = useContext(AuthContext)
  const classes = useStyles()

  const { userId, isCognitoInitialized, isROAdmin, isAdmin } = authContextState

  Sentry.withScope((scope) => {
    userId && scope.setTag("id", userId || "")
  })

  const gaMeasurementId = (window as any).GA_MEASUREMENT_ID

  const adminView = useMemo(() => isAdmin || isROAdmin, [isAdmin, isROAdmin])

  const isInMaintenanceMode =
    (window as any).MAINTENANCE_MODE === "true" ? true : false

  function getDashboard() {
    if (adminView) return AdminDashboard
    if (isInMaintenanceMode) return MaintenancePage
    return Dashboard
  }

  return (
    <Container maxWidth='lg'>
      <VersionChecker />
      <ToastAlert />
      {isCognitoInitialized ? (
        <Router>
          <MainProvider>
            <Analytics id={gaMeasurementId} trackPathnameOnly>
              <Switch>
                <Route exact={true} path='/login' component={Login} />
                <Route exact={true} path='/signup' component={Signup} />
                <Route exact={true} path='/privacy' component={PagePrivacy} />
                <Route
                  exact={true}
                  path='/data-policy'
                  component={PageDataPolicy}
                />
                <Route
                  exact={true}
                  path='/enter-code'
                  component={AnswerChallenge}
                />
                <Route
                  exact={true}
                  path='/enter-password'
                  component={PasswordForm}
                />
                <ProtectedRoute path='/' component={getDashboard()} />
              </Switch>
            </Analytics>
          </MainProvider>
        </Router>
      ) : (
        <Spinner className={classes.spinner} />
      )}
      <CookieConsentPrompt
        cookieName={GA.COOKIE_NAME}
        message={
          <span>
            We use cookies in order to offer you a better browsing experience.
            Learn more at our{" "}
            <Link
              className={classes.snackbarLink}
              href='/privacy'
              target='_blank'
            >
              privacy page
            </Link>
          </span>
        }
        onAccept={GA.handleCookieConsent}
        onDeny={GA.handleCookieDeny}
      />
    </Container>
  )
}

export default App
