import { cloneDeep } from "lodash"
import { JSONSchema7 } from "json-schema"
import jsonpointer from "jsonpointer"

// Utility functions for working with React Schema-JSON Forms

// Gets schema with all $ref references inlined
export const getSchemaWithDefinitions = (
  schema: object,
  rootSchema: object,
): JSONSchema7 => {
  let clonedSchema = cloneDeep(schema)

  // Recursively check schema for object properties
  const checkForRefs = (subSchema: any) => {
    Object.keys(subSchema).forEach((key) => {
      //If $ref exists, import reference from definition
      if (key === "$ref") {
        const ref = findSchemaDefinition(subSchema.$ref, rootSchema)
        Object.assign(subSchema, ref)
        delete subSchema.$ref
      }
      // Make recursive call
      if (typeof subSchema[key] === "object") {
        checkForRefs(subSchema[key])
      }
    })
  }

  checkForRefs(clonedSchema)

  return clonedSchema
}

export const getDefinitionName = (schema: any): string | null => {
  const $ref = schema.$ref || schema.items ? schema.items?.$ref : null

  if ($ref) {
    const segments = $ref.split("/")
    return segments[segments.length - 1]
  }

  return null
}

export const findSchemaDefinition = ($ref: string, rootSchema = {}): any => {
  const origRef = $ref
  if ($ref.startsWith("#")) {
    // Decode URI fragment representation.
    $ref = decodeURIComponent($ref.substring(1))
  } else {
    throw new Error(`Could not find a definition for ${origRef}.`)
  }
  const current = jsonpointer.get(rootSchema, $ref)
  if (current === undefined) {
    throw new Error(`Could not find a definition for ${origRef}.`)
  }
  if (current.hasOwnProperty("$ref")) {
    return findSchemaDefinition(current.$ref, rootSchema)
  }
  return current
}
