import React, { useCallback, useContext, useMemo } from "react"
import { FieldProps } from "@rjsf/core"
import TitleField from "../../TitleField"
import "react-datasheet/lib/react-datasheet.css"
import {
  DEIProfileLink,
  getDEIProfilePath,
} from "../../../components/Navbar/Navbar"
import { MainContext } from "../../../context/MainContext"
import DescriptionField from "../../DescriptionField"

// DEIComposition object will have a country and a gridSet corresponding to said country

// DeiGrid definition:
// =""""&E4&""": {""$ref"": ""#/definitions/DeiRow"", ""title"": """&D4&"""},"

export const DEIComposition = (props: FieldProps) => {
  const { schema } = props
  const { currentUser } = useContext(MainContext)

  const profilePath = currentUser?.organization?.allowMultiFirm
    ? getDEIProfilePath()
    : getDEIProfilePath(currentUser?.organization?.primaryFirm?.rcgFirmId)

  return (
    <div className='dei-composition'>
      {schema.title && <TitleField title={schema.title} />}
      {schema.description && (
        <DescriptionField description={schema.description} />
      )}
      <div style={{ marginTop: "1em", marginBottom: "2em" }}>
        <DEIProfileLink
          buttonProps={{ variant: "contained", color: "primary" }}
          profilePath={profilePath}
          buttonText='Enter Firm DEI'
        />
      </div>
    </div>
  )
}

export default DEIComposition
