import React from "react"
//import { makeStyles } from "@material-ui/core/styles"
import Alert from "@material-ui/lab/Alert"
import { ErrorListProps } from "@rjsf/core"
import { get } from "lodash"
import { JSONSchema7Definition, JSONSchema7 } from "json-schema"
import * as Sentry from "@sentry/react"

export const ErrorListTemplate = (props: any) => {
  const {
    errors,
    errorSchema,
    schema,
    formContext,
    formData,
  } = props as ErrorListProps & { formData?: any }

  Sentry.withScope((scope) => {
    if (formContext) {
      Object.keys(formContext).forEach((key) =>
        scope.setTag(key, formContext[key]),
      )
    }
    errors.forEach((error) => {
      Sentry.captureException(new Error(`${error.stack}`))
    })
  })

  if (!Array.isArray(errors)) {
    console.error(errors)
  }

  return (
    <div data-cy='error-list-template' className='error-list-template'>
      <Alert severity='error'>
        {errors.length} question{errors.length > 1 && "s"}{" "}
        {errors.length > 1 ? "have" : "has"} validation errors. Please adjust
        {errors.length > 1 ? " them" : " it"} as necessary before clicking{" "}
        <strong>Submit</strong>
        <ul>
          {errors.map((error) => {
            const [questionId] = error.stack.split(":")
            return <li key={questionId}>{error.stack}</li>
          })}
        </ul>
      </Alert>
    </div>
  )
}

export default ErrorListTemplate
