import React, { useState } from "react"
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { v4 as uuid } from "uuid"
import useInterval from "use-interval"

export const VersionChecker = () => {
  const [version, setVersion] = useState<string | undefined>(
    (window as any).APP_VERSION,
  )
  const [notificationOpen, changeNotificationOpen] = useState(false)

  const check = async () => {
    console.log(
      `checking for new version at: ${new Date().getHours()}:${new Date()
        .getMinutes()
        .toString()
        .padStart(2, "0")}`,
    )
    const newVersion = await (
      await (await fetch(`/version.txt?q=${uuid()}`)).text()
    ).replace("\n", "")

    if (!newVersion) return

    if (!version || version !== newVersion) {
      console.log(newVersion)
      setVersion(newVersion)
      if (!version) return
      if (!notificationOpen) {
        changeNotificationOpen(true)
      }
    }
  }

  useInterval(check, 1000 * 60 * 5, true)

  return (
    <Snackbar
      open={notificationOpen}
      onClose={() => changeNotificationOpen(false)}
      autoHideDuration={null}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message='New Version of Data Collection'
      action={() => window.location.reload()}
    >
      <Alert
        onClose={() => changeNotificationOpen(false)}
        onClick={() => window.location.reload()}
        severity='info'
      >
        New Version of Objective Data Collection Click to Reload
      </Alert>
    </Snackbar>
  )
}
