import React from "react"
import {
  Button as MButton,
  styled,
  ButtonProps as MButtonProps,
} from "@material-ui/core"

interface ButtonProps extends MButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  to?: string | undefined | null
  style?: any
}

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return <MButton {...props}>{children}</MButton>
}

export default styled(Button)({
  minWidth: "240px",
  margin: "1rem 0",
})
