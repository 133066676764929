import Form from "@rjsf/material-ui"
import { Card, CardContent } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { JSONSchema7Object } from "json-schema"
import React, { useContext, useState, useMemo, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { default as rawSchema } from "../../data/signup-schema.json"
import { default as ui } from "../../data/signup-ui-schema.json"
import { Button } from "../index"
import { AuthContext, UserRegistrationInput } from "../../context/AuthContext"
import { toast } from "react-toastify"
import { Error } from "../../Toast"
import { FirmInput } from "graphql-types/generated/portal-client-types"
import ErrorListTemplate from "../form/ErrorListTemplate"
import SimpleNavbar from "../Navbar/SimpleNavbar"
import { Link } from "react-router-dom"
import { useApolloClient, useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { useDebouncedCallback } from "use-debounce"

const VERIFY_ORGANIZATION_NAME = gql`
  query checkOrganizationName($name: String!) {
    isOrganizationNameUnique(name: $name)
  }
`

const VERIFY_EMAIL_MAPPING = gql`
  query checkEmailMapping($email: String!) {
    emailMappingOrg(email: $email)
  }
`

const useStyles = makeStyles(() => ({
  intro: {
    paddingBottom: "16px !important",
    "& p:first-child": {
      marginTop: 0,
    },
    "& p:last-child": {
      marginBotom: 0,
    },
    "& ul": {
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
  },
  terms: { marginLeft: "10px" },
}))

export const Signup = () => {
  const classes = useStyles()
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false)
  const [
    { email: cognitoUserEmail, isMachineUser },
    { signUp, signIn },
  ] = useContext(AuthContext)

  const client = useApolloClient()

  const [verifiedOrganizationName, setVerifiedOrganizationName] = useState<
    string | undefined
  >()

  const [extraErrors, setExtraErrors] = useState<undefined | any>(undefined)

  //react json form will clear data in some situations, so it is stored in state.
  //https://github.com/rjsf-team/react-jsonschema-form/issues/391
  const [formDataState, setFormDataState] = useState<any>({})

  //just send domain so we cache by domain rather than keystroke.
  const domain =
    formDataState?.email && formDataState?.email.split("@")[1]
      ? "@" + formDataState?.email.split("@")[1]
      : undefined

  const { data, loading } = useQuery(VERIFY_EMAIL_MAPPING, {
    variables: {
      email: domain,
    },
    skip: !domain,
  })

  const schema = useMemo<JSONSchema7Object>(() => {
    if (data?.emailMappingOrg) {
      return {
        ...rawSchema,
        required: ["first_name", "last_name", "email"],
        properties: {
          first_name: rawSchema.properties.first_name,
          last_name: rawSchema.properties.last_name,
          email: rawSchema.properties.email,
        },
      }
    }
    return rawSchema
  }, [data])

  const uiSchema = useMemo<JSONSchema7Object>(() => {
    if (data?.emailMappingOrg) {
      return { "ui:order": ["first_name", "last_name", "email"] }
    }
    return ui
  }, [data])

  const verifySchema = useDebouncedCallback(async ({ formData }: any) => {
    if (
      formData.organization_name &&
      verifiedOrganizationName !== formData.organization_name
    ) {
      const {
        data: { isOrganizationNameUnique },
      } = await client.query({
        query: VERIFY_ORGANIZATION_NAME,
        variables: {
          name: formData.organization_name,
        },
      })

      if (isOrganizationNameUnique) {
        setExtraErrors(undefined)
        setVerifiedOrganizationName(formData.organization_name)
      } else if (data?.emailMappingOrg) {
        setExtraErrors(undefined)
      } else {
        setExtraErrors({
          organization_name: {
            __errors: [
              `Organization -- ${formData.organization_name} -- already claimed in system, please add a location to the name, or contact portal@therockcreekgroup.com for support.`,
            ],
          },
        })
      }
    }
  }, 200)

  const handleChange = ({ formData, ...rest }: any) => {
    setFormDataState(formData)
    if (
      formData.organization_name &&
      verifiedOrganizationName !== formData.organization_name
    ) {
      setVerifiedOrganizationName(undefined)
    }
    verifySchema({ formData, ...rest })
  }

  const handleSubmit = async (event: any): Promise<void> => {
    try {
      const {
        email,
        first_name,
        last_name,
        street_address_1,
        street_address_2,
        city,
        state,
        zip,
        country,
        firm_type_id,
        organization_name,
        size,
        url,
      } = event.formData

      const user: UserRegistrationInput = {
        email,
        firstName: first_name,
        lastName: last_name,
        streetAddress1: street_address_1,
        streetAddress2: street_address_2,
        city,
        state,
        zipCode: zip,
        country,
      }

      let organization: FirmInput
      if (data?.emailMappingOrg) {
        organization = {
          name: data.emailMappingOrg,
        }
      } else {
        organization = {
          firmTypeId: firm_type_id,
          name: organization_name,
          size: null,
          sizeSymbol: size, // size is now a varchar. store in size_symbol
          url,
        }
      }

      await signUp(user, organization)
      await signIn(email, user, organization)
      setShouldRedirect(true)
    } catch (error) {
      toast(<Error body={error.message} />)
      console.error(error)
    }
  }

  if (shouldRedirect) {
    return <Redirect to='/enter-code' />
  }

  return (
    <div className='signup'>
      <SimpleNavbar />
      <Card>
        <CardContent className={classes.intro}>
          Thank you for your interest in submitting your information to the
          Objective Data Collective platform. Please provide your information
          below to register. Once you submit your information, a security code
          will be sent to your inbox to verify your email address.
        </CardContent>
      </Card>
      <Form
        formData={formDataState}
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={handleSubmit}
        onChange={handleChange}
        extraErrors={extraErrors}
        ErrorList={ErrorListTemplate}
      >
        {data?.emailMappingOrg && (
          <h3>
            <em>Organization Matched: {data?.emailMappingOrg}</em>
          </h3>
        )}

        <Button
          variant='contained'
          color='primary'
          type='submit'
          disabled={
            (!verifiedOrganizationName && !data?.emailMappingOrg) || loading
          }
        >
          Register
        </Button>
        <span className={classes.terms}>
          By registering, you agree to the{" "}
          <Link to='/data-policy' target='_blank'>
            Terms and Conditions
          </Link>
        </span>
      </Form>
    </div>
  )
}

export default Signup
