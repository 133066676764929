import { isEmpty, isNaN } from "lodash"
import { ColumnDefinition } from "./ColumnDefinitions"

export const getCellValue = (
  value: any,
  column: ColumnDefinition | null = null,
) => {
  if (isEmpty(value)) {
    return null
  }

  if (column === null || column.type === "number") {
    const valueNumbersOnly = value.replace(/[^-0123456789.]/g, "")
    const numericValue = Number(valueNumbersOnly)
    return isNaN(numericValue) ? null : numericValue
  }

  return value
}
