import React from "react"
import Alert from "@mui/material/Alert"
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant"
import SimpleNavbar from "./Navbar/SimpleNavbar"

const isInMaintenanceMode =
  (window as any).MAINTENANCE_MODE === "true" ? true : false

const maintenanceMessage =
  "We are working hard to improve your experience. The RockCreek Portal will return in a few minutes"

export const MaintenanceModeBanner = () => {
  return isInMaintenanceMode ? <Banner /> : null
}

const Banner = () => (
  <Alert icon={<NotificationImportantIcon />} color='warning'>
    {maintenanceMessage}
  </Alert>
)

export default function MaintenanceMode() {
  return (
    <>
      <SimpleNavbar />
    </>
  )
}
