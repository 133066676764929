import * as React from "react"
import { styled, alpha, hslToRgb } from "@mui/material/styles"
import Menu, { MenuProps } from "@mui/material/Menu"

export const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      backgroundColor: "hsla(211deg,84%,32%,1)",
    },
    a: {
      textDecoration: "none",
      color: "hsl(0deg,0%,85%)",
    },
  }),
)
