import React from "react"
import { toast } from "react-toastify"
import Form from "../components/CustomForm"
import { JSONSchema7Object } from "json-schema"
import { useHistory } from "react-router-dom"
import { Button, Typography } from "@material-ui/core"
import { Error } from "../Toast"
import { default as rawSchema } from "../schema/create-firm-schema.json"
import { default as ui } from "../data/ui-schema.json"
import * as types from "graphql-types/generated/portal-client-types"
import { useMutation, gql } from "@apollo/client"
import {
  reportErrorWithFormData,
  returnHumanReadableError,
} from "../utils/errorHandler"

const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($input: OrganizationInput!) {
    createOrganization(input: $input) {
      id
    }
  }
`

export const CreateFirmPage = () => {
  const [createOrganization, { error }] = useMutation(CREATE_ORGANIZATION)
  const schema = rawSchema as JSONSchema7Object
  const uiSchema = ui as JSONSchema7Object
  const history = useHistory()

  const handleSubmit = (event: any) => {
    const submit = async () => {
      const {
        email_domain,
        firm_type_id,
        organization_name,
        size,
        size_symbol,
        url,
      } = event.formData

      const organizationInput: types.OrganizationInput = {
        emailDomain: email_domain,
        name: organization_name,
        firms: [
          {
            firmTypeId: firm_type_id,
            size: size ? Number(size) : null,
            sizeSymbol: size_symbol,
            url,
          },
        ],
      }

      await createOrganization({
        variables: { input: organizationInput },
      })
      if (error) {
        toast(<Error body={returnHumanReadableError(error)} />)
      } else {
        history.push("/")
      }
    }
    submit()
  }

  return (
    <div className='create-firm-page'>
      <Typography variant='h5' component='h5' gutterBottom>
        Create Firm
      </Typography>
      <Form schema={schema} uiSchema={uiSchema} onSubmit={handleSubmit}>
        <Button variant='contained' color='primary' type='submit'>
          Create
        </Button>
      </Form>
    </div>
  )
}

export default CreateFirmPage
