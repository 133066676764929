import Form from "@rjsf/material-ui"
import { JSONSchema7Object } from "json-schema"
import React, { useContext, useEffect } from "react"
import { Error } from "../../Toast"
import { useHistory } from "react-router-dom"
import { default as rawSchema } from "../../data/answer-challenge-schema.json"
import { default as ui } from "../../data/ui-schema.json"
import { Button } from "../index"
import { AuthContext } from "../../context/AuthContext"
import { toast } from "react-toastify"
import ErrorListTemplate from "../form/ErrorListTemplate"
import SimpleNavbar from "../Navbar/SimpleNavbar"

export const AnswerChallenge = () => {
  const history = useHistory()
  //const [schema] = useState<JSONSchema7Object>(login)
  const [authState, { answerCustomChallenge }] = useContext(AuthContext)
  const schema = rawSchema as JSONSchema7Object
  const uiSchema = ui as JSONSchema7Object

  const handleSubmit = async (event: any): Promise<void> => {
    try {
      const { answer } = event.formData
      await answerCustomChallenge(answer)
    } catch (error) {
      toast(<Error body={error.message} />)
      console.error(error)
    }
  }

  useEffect(() => {
    if (authState.isAuthenticated) {
      history.push("/")
    }
  }, [authState.isAuthenticated, history])

  return (
    <div className='answer-challenge'>
      <SimpleNavbar />
      <div>
        Please enter the secret sign-in code that was sent to your email address
        to complete sign-in
      </div>

      <Form
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={handleSubmit}
        ErrorList={ErrorListTemplate}
      >
        <Button variant='contained' color='primary' type='submit'>
          Confirm
        </Button>
      </Form>
    </div>
  )
}

export default AnswerChallenge
