import React, { useMemo } from "react"
import { WidgetProps } from "@rjsf/core"
import { format } from "date-fns"
import { styled } from "@material-ui/core"
import classnames from "classnames"

export default function CustomDateInput(props: WidgetProps) {
  const { registry, onChange } = props
  const { BaseInput } = registry.widgets

  const StyledInput = useMemo(
    () =>
      styled(BaseInput)({
        font: "inherit",
        letterSpacing: "inherit",
        color: "inherit",
        paddingTop: "4px",
        paddingBottom: "5px",
        border: 0,
        height: "1.4375em",
        margin: 0,
        display: "block",
        minWidth: 0,
        boxSizing: "content-box",
        width: "100%",
        paddingRight: 0,
        "&:focus": {
          outline: 0,
        },
      }),
    [BaseInput],
  )

  function handleFocus(event: React.FocusEvent) {
    const { currentTarget } = event
    currentTarget.classList.add("Mui-focused")
    currentTarget.previousElementSibling?.classList.add("Mui-focused")
  }

  function handleBlur(event: React.FocusEvent) {
    const { currentTarget } = event
    currentTarget.classList.remove("Mui-focused")
    currentTarget.previousElementSibling?.classList.remove("Mui-focused")
  }

  return (
    <>
      <label
        className={classnames(
          "MuiFormLabel-root",
          "MuiInputLabel-root",
          "MuiInputLabel-formControl",
          "MuiInputLabel-animated",
          "MuiInputLabel-shrink",
          props.required && "Mui-required",
        )}
        data-shrink='true'
        htmlFor={`${props.id}`}
        id={`${props.id}-label`}
      >
        {props.label}
      </label>
      <div
        onFocus={handleFocus}
        onBlur={handleBlur}
        className='MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl'
      >
        <StyledInput
          {...props}
          type='date'
          min={format(new Date("1901"), "yyyy-MM-dd")}
          onChange={(value: any) => onChange(value ?? undefined)}
        />
      </div>
    </>
  )
}
