import React, { useEffect, useState, useCallback } from "react"
import { FieldProps, UiSchema } from "@rjsf/core"
import TitleField from "../TitleField"
import DescriptionField from "../DescriptionField"
import { Box } from "@material-ui/core"
import { JSONSchema7 } from "json-schema"
import "react-datasheet/lib/react-datasheet.css"
import { set, cloneDeep } from "lodash"
import { getSchemaWithDefinitions } from "../rsjfUtils"
import XlsGrid from "./XlsGrid"

// Wrapper class for XlsGrid, where each property represents its own grid

type ChildGrid = {
  propName: string
  schema: JSONSchema7
  formData: any
  uiSchema: UiSchema
  onChange: (key: string, value: any) => void
}

export const ExposureGridSet = (props: FieldProps) => {
  const { schema, uiSchema, formData, onChange, registry } = props
  const [grids, setGrids] = useState<ChildGrid[] | null>(null)
  const registryAny: any = registry
  const rootSchema = registryAny.rootSchema

  const handleChange = useCallback(
    (key: string, value: any) => {
      let nextFormData = cloneDeep(formData)

      set(nextFormData, key, value)

      onChange(nextFormData)
    },
    [formData, onChange],
  )

  // Each property represents its own grid
  useEffect(() => {
    let nextGrids: ChildGrid[] = []

    const schemaWithDefinitions = getSchemaWithDefinitions(schema, rootSchema)

    if (!schemaWithDefinitions.properties) {
      return
    }

    const formDataOrDefault = formData || {}
    // Each property represents a grid
    Object.entries(schemaWithDefinitions.properties).forEach(
      (childEntry: any) => {
        const [propName, childSchema] = childEntry

        // Merge parent/child schema
        let childUiSchema = Object.assign({}, uiSchema, uiSchema[propName])
        set(childUiSchema, "ui:options.showTitleInGrid", true)

        const grid = {
          propName,
          schema: childSchema,
          formData: formDataOrDefault[propName],
          uiSchema: childUiSchema,
          onChange: handleChange.bind(null, propName),
        }

        nextGrids.push(grid)
      },
    )

    setGrids(nextGrids)
  }, [schema, formData, rootSchema, uiSchema, handleChange])

  if (!grids || !grids.length) {
    return null
  }

  return (
    <div className='exposure-grid-set'>
      {schema.title && <TitleField title={schema.title} />}
      {schema.description && (
        <DescriptionField description={schema.description} />
      )}
      <Box style={{ marginTop: "1em" }}>
        {grids.map((grid) => (
          <XlsGrid
            key={grid.propName}
            {...props}
            schema={grid.schema}
            uiSchema={grid.uiSchema}
            formData={grid.formData}
            onChange={grid.onChange}
          />
        ))}
      </Box>
    </div>
  )
}

export default ExposureGridSet
