import React from "react"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
}))

const PagePrivacy = () => {
  const classes = useStyles()

  return (
    <div className={`PagePrivacy ${classes.root}`}>
      <Container>
        <h2>Online Privacy Notice</h2>
        <p>
          Thank you for visiting our web site. This privacy policy tells you how
          we use personal information collected at this site. Please read this
          privacy policy before using the site or submitting any personal
          information. By using the site, you are accepting the practices
          described in this privacy policy. These practices may be changed, but
          any changes will be posted and changes will only apply to activities
          and information on a going forward, not retroactive basis. You are
          encouraged to review the privacy policy whenever you visit the site to
          make sure that you understand how any personal information you provide
          will be used.
        </p>
        <p>
          <strong>Note:</strong> the privacy practices set forth in this privacy
          notice are for this website only. If you link to other web sites,
          please review the privacy policies posted at those sites.
        </p>
        <p>
          <strong>Collection of Information:</strong>
          We collect personally identifiable information, like names, postal
          addresses, email addresses, etc.
        </p>

        <h2>Cookie/Tracking Technology</h2>
        <p>
          The site may use cookie and tracking technology depending on the
          features offered. Cookie and tracking technology are useful for
          gathering information such as browser type and operating system,
          tracking the number of visitors to the Site, and understanding how
          visitors use the Site. Cookies can also help customize the site for
          visitors.
        </p>

        <h2>Information we Obtain from Automated Means</h2>
        <p>
          We may use third-party web analytics services on our Sites, such as
          those of Google Analytics. These service providers help us analyze how
          visitors use the Sites. The information obtained for this purpose
          (including your IP address and other information collected by
          automated means) will be disclosed to or collected directly by these
          service providers. Portions of this website may use Google Analytics,
          a web analytics service provided by Google, Inc. (“Google”). Google
          Analytics uses “cookies”, which are text files placed on your
          computer, to help the website analyze how users use the site. The
          information generated by the cookie about your use of the website
          (including your IP address) will be transmitted to and stored by
          Google on servers in the United States. Google will use this
          information for the purpose of evaluating your use of the website,
          compiling reports on website activity for website operators and
          providing other services relating to website activity and internet
          usage. Google may also transfer this information to third parties
          where required to do so by law, or where such third parties process
          the information on Google's behalf. Google will not associate your IP
          address with any other data held by Google. You may refuse the use of
          cookies by selecting the appropriate settings on your browser, however
          please note that if you do this you may not be able to use the full
          functionality of this website. By using this website, you consent to
          the processing of data about you by Google in the manner and for the
          purposes set out above. To learn about how Google Analytics uses data,
          please visit:{" "}
          <a
            href='https://www.google.com/policies/privacy/partners/'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.google.com/policies/privacy/partners/
          </a>
          .
        </p>

        <h2>Use of Information</h2>
        <p>We may use the information described above to:</p>
        <ul>
          <li>
            Provide products and services to you, such as market commentary and
            email alerts; send you promotional materials, newsletters and other
            communications;
          </li>
          <li>
            communicate with you about, and administer your participation in,
            special events and to respond to your inquiries;
          </li>
          <li>
            protect against, identify and prevent fraud and other unlawful
            activity; and
          </li>
          <li>
            comply with and enforce applicable legal requirements, relevant
            industry standards and our policies, including our Terms and
            Conditions of Use.
          </li>
          <li>
            We also may use the information in other ways for which we provide
            specific notice at the time of collection.
          </li>
        </ul>

        <h2>Distribution of Information</h2>
        <p>
          We may share information with governmental agencies or other companies
          assisting us in fraud prevention or investigation. We may do so when:
          (1) permitted or required by law; or, (2) trying to protect against or
          prevent actual or potential fraud or unauthorized transactions; or,
          (3) investigating fraud which has already taken place. The information
          is not provided to these companies for marketing purposes.
        </p>

        <h2>Commitment to Data Security</h2>
        <p>
          Your personally identifiable information is kept secure. Only
          authorized employees, agents and contractors (who have agreed to keep
          information secure and confidential) have access to this information.
        </p>

        <h2>Privacy Contact Information</h2>
        <p>
          If you have any questions, concerns, or comments about this privacy
          notice, please contact us at:{" "}
          <a href='mailto:information@therockcreekgroup.com'>
            information@therockcreekgroup.com
          </a>
        </p>
      </Container>
    </div>
  )
}

export default PagePrivacy
