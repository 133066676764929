import { gql } from "@apollo/client"

export const GET_FORM = gql`
  query GetForm($id: String!) {
    submitted(id: $id) {
      id
      firmId
      userId
      email
      sourceId
      source {
        id
        name
      }
      userInput
      name
      updatedAt
    }
  }
`

export const UPDATE_SUBMITTED = gql`
  mutation UpdateSubmitted($id: String!, $input: SubmittedUpdate!) {
    updateSubmitted(id: $id, input: $input) {
      id
      firmId
      userId
      email
      sourceId
      source {
        id
        name
      }
      userInput
      name
      updatedAt
    }
  }
`

export const DELETE_SUBMITTED = gql`
  mutation DeleteSubmitted($id: String!) {
    deleteSubmitted(id: $id)
  }
`
