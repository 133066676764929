import React, { useContext, useState, useMemo } from "react"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/react"
import { Error } from "../Toast"
import { Link, useRouteMatch } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { Table, Button } from "@material-ui/core"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { TablePagination } from "@material-ui/core"
import { useMutation, useQuery } from "@apollo/client"
import { get } from "lodash"
import * as types from "graphql-types/generated/portal-client-types"
import { AuthContext } from "../context/AuthContext"
import AssetClassSelect from "../components/AssetClassSelect"
import SearchInput from "../components/SearchInput"
import BoardRow from "./BoardRow"
import OrganizationBoardRow from "./OrganizationBoardRow"
import { GET_ALL_FIRMS } from "./graphql"
import { GET_ASSET_CLASSES } from "../components/AssetSelection/graphql"
import { DELETE_SUBMITTED } from "../components/AssetForm/graphql"
import AddIcon from "@material-ui/icons/Add"
import Spinner from "../components/Spinner"
import { DELETE_FIRM } from "../components/FirmProfile/graphql"

function sortOrgsByFirmName(org: types.Organization): string {
  if (!org.serviceProviderFirms.length) return org.primaryFirm?.name ?? ""

  return org.serviceProviderFirms[0].name ?? ""
}

const useRowStyles = makeStyles({
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
})

const Board: React.FC = () => {
  const classes = useRowStyles()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [filter, setFilter] = useState("")
  const [assetClassFilter, setAssetClassFilter] = useState(0)
  const [investmentName, setInvestmentName] = useState("")
  const [callDeleteFirmApi] = useMutation(DELETE_FIRM)
  const [callDeleteSubmittedApi] = useMutation(DELETE_SUBMITTED)

  const { data, loading, refetch, error } = useQuery(GET_ALL_FIRMS, {
    variables: {
      page: { limit: rowsPerPage, offset: rowsPerPage * page },
      sort: [{ field: "name", order: "ASC" }],
      firmFilter: {
        q: filter,
        assetClassId: assetClassFilter ? assetClassFilter : undefined,
        investmentName,
      },
      submissionFilter: {
        assetClassId: assetClassFilter ? assetClassFilter : undefined,
        investmentName,
      },
    },
    fetchPolicy: "cache-and-network",
  })
  const { data: assetClassData, loading: assetClassesLoading } = useQuery<{
    assetClassEnumList: types.AssetClassListQuery["assetClassEnumList"]
  }>(GET_ASSET_CLASSES)

  const [authContextState] = useContext(AuthContext)
  const { path } = useRouteMatch()

  const firms: types.Firm[] = useMemo(() => {
    return get(data, "firmList.items", [])
  }, [data, loading, page, rowsPerPage, filter, refetch])

  const totalCount = useMemo(() => {
    return get(data, "firmList.total", 0)
  }, [data, loading, page, rowsPerPage, filter])

  const assetClasses = useMemo(() => {
    // \xa0 === nbsp
    return [{ id: 0, name: "\xa0" }].concat(
      assetClassData?.assetClassEnumList ?? [],
    )
  }, [assetClassData, assetClassesLoading])

  async function handleSearchChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { value } = event.target
    setFilter(value)
    setPage(0)
  }

  async function handleAssetClassChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) {
    const { value } = event.target
    setAssetClassFilter(value as any)
    setPage(0)
  }

  async function handleInvestmentNameChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { value } = event.target
    setInvestmentName(value)
    setPage(0)
  }

  function handleChangePage(_event: unknown, newPage: number) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  async function deleteFirm(firm: types.Firm) {
    const { id } = firm
    await callDeleteFirmApi({ variables: { id } })
    refetch()
  }

  async function deleteSubmission(submission: types.Submitted) {
    const { id } = submission
    await callDeleteSubmittedApi({ variables: { id } })
    refetch()
  }

  if (error) {
    toast(<Error body='Error fetching firms, please refresh and try again' />)
  }

  return (
    <div data-cy='admin-board' className='board'>
      <div className={classes.toolBar}>
        <SearchInput
          label='Filter By Firm Name'
          value={filter}
          onChange={handleSearchChange}
        />
        <SearchInput
          label='Filter By Investment Name'
          value={investmentName}
          onChange={handleInvestmentNameChange}
        />
        <AssetClassSelect
          label='Filter By Asset Class'
          data={assetClasses}
          value={assetClassFilter}
          onChange={handleAssetClassChange}
        />
        {!authContextState.isROAdmin && (
          <Link to='/create-firm'>
            <Button variant='contained' color='primary' startIcon={<AddIcon />}>
              Add Firm
            </Button>
          </Link>
        )}
      </div>
      <div style={{ margin: "1rem 0 5rem 0" }}>
        <TableContainer component={Paper}>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Firm Name</TableCell>
                <TableCell align='left'>Website</TableCell>
                <TableCell align='left'>Firm Profile</TableCell>
                {authContextState.isAdmin === true && <TableCell />}
              </TableRow>
            </TableHead>

            <TableContent
              firms={firms}
              loading={loading}
              onDeleteFirm={deleteFirm}
              onDeleteSubmission={deleteSubmission}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  )
}

interface TableContentProps {
  firms: types.Firm[]
  loading: boolean
  onDeleteFirm(firm: types.Firm): Promise<void>
  onDeleteSubmission(submission: types.Submitted): Promise<void>
}
function TableContent({
  firms,
  loading,
  onDeleteFirm,
  onDeleteSubmission,
}: TableContentProps) {
  const organizations = useMemo(() => {
    return firms
      .reduce((accu: types.Organization[], curr: types.Firm) => {
        if (curr.organization) return accu.concat(curr.organization ?? [])
        return accu
      }, [])
      .sort((x, y) =>
        sortOrgsByFirmName(x).localeCompare(sortOrgsByFirmName(y)),
      )
  }, [firms])

  if (loading)
    return (
      <TableBody>
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      </TableBody>
    )

  return (
    <TableBody>
      {organizations.map((org) => {
        if (org.serviceProviderFirms.length > 0) {
          return (
            <OrganizationBoardRow
              key={org.id}
              organization={org}
              onDeleteFirm={onDeleteFirm}
              onDeleteSubmission={onDeleteSubmission}
            />
          )
        } else if (org.primaryFirm) {
          return (
            <BoardRow
              key={org.primaryFirm.id}
              firm={org.primaryFirm}
              indent={false}
              onDeleteFirm={onDeleteFirm}
              onDeleteSubmission={onDeleteSubmission}
            />
          )
        }
      })}
    </TableBody>
  )
}

export default Board
