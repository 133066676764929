export const currencies = [
  "USD - US Dollar",
  "EUR - Euro",
  "JPY - Yen",
  "GBP - Pound Sterling",
  "AUD - Australian Dollar",
  "CAD - Canadian Dollar",
  "CHF - Swiss Franc",
  "CNY - Yuan Renminbi",
  "HKD - Hong Kong Dollar",
  "NZD - New Zealand Dollar",
  "SEK - Swedish Krona",
  "KRW - Won",
  "SGD - Singapore Dollar",
  "NOK - Norwegian Krone",
  "MXN - Mexican Peso",
  "INR - Indian Rupee",
  "RUB - Russian Ruble",
  "ZAR - Rand",
  "TRY - Turkish Lira",
  "BRL - Brazilian Real",
  "AED - UAE Dirham",
  "AFN - Afghani",
  "ALL - Lek",
  "AMD - Armenian Dram",
  "ANG - Netherlands Antillean Guilder",
  "AOA - Kwanza",
  "ARS - Argentine Peso",
  "AWG - Aruban Florin",
  "AZN - Azerbaijanian Manat",
  "BAM - Convertible Mark",
  "BBD - Barbados Dollar",
  "BDT - Taka",
  "BGN - Bulgarian Lev",
  "BHD - Bahraini Dinar",
  "BIF - Burundi Franc",
  "BMD - Bermudian Dollar",
  "BND - Brunei Dollar",
  "BOB - Boliviano",
  "BOV - Mvdol",
  "BSD - Bahamian Dollar",
  "BTN - Ngultrum",
  "BWP - Pula",
  "BYN - Belarussian Ruble",
  "BZD - Belize Dollar",
  "CDF - Congolese Franc",
  "CHE - WIR Euro",
  "CHW - WIR Franc",
  "CLF - Unidad de Fomento",
  "CLP - Chilean Peso",
  "COP - Colombian Peso",
  "COU - Unidad de Valor Real",
  "CRC - Costa Rican Colon",
  "CUC - Peso Convertible",
  "CUP - Cuban Peso",
  "CVE - Cabo Verde Escudo",
  "CZK - Czech Koruna",
  "DJF - Djibouti Franc",
  "DKK - Danish Krone",
  "DOP - Dominican Peso",
  "DZD - Algerian Dinar",
  "EGP - Egyptian Pound",
  "ERN - Nakfa",
  "ETB - Ethiopian Birr",
  "FJD - Fiji Dollar",
  "FKP - Falkland Islands Pound",
  "GEL - Lari",
  "GHS - Ghana Cedi",
  "GIP - Gibraltar Pound",
  "GMD - Dalasi",
  "GNF - Guinea Franc",
  "GTQ - Quetzal",
  "GYD - Guyana Dollar",
  "HNL - Lempira",
  "HRK - Kuna",
  "HTG - Gourde",
  "HUF - Forint",
  "IDR - Rupiah",
  "ILS - New Israeli Sheqel",
  "IQD - Iraqi Dinar",
  "IRR - Iranian Rial",
  "ISK - Iceland Krona",
  "JMD - Jamaican Dollar",
  "JOD - Jordanian Dinar",
  "KES - Kenyan Shilling",
  "KGS - Som",
  "KHR - Riel",
  "KMF - Comoro Franc",
  "KPW - North Korean Won",
  "KWD - Kuwaiti Dinar",
  "KYD - Cayman Islands Dollar",
  "KZT - Tenge",
  "LAK - Kip",
  "LBP - Lebanese Pound",
  "LKR - Sri Lanka Rupee",
  "LRD - Liberian Dollar",
  "LSL - Loti",
  "LYD - Libyan Dinar",
  "MAD - Moroccan Dirham",
  "MDL - Moldovan Leu",
  "MGA - Malagasy Ariary",
  "MKD - Denar",
  "MMK - Kyat",
  "MNT - Tugrik",
  "MOP - Pataca",
  "MRU - Ouguiya",
  "MUR - Mauritius Rupee",
  "MVR - Rufiyaa",
  "MWK - Kwacha",
  "MXV - Mexican Unidad de Inversion (UDI)",
  "MYR - Malaysian Ringgit",
  "MZN - Mozambique Metical",
  "NAD - Namibia Dollar",
  "NGN - Naira",
  "NIO - Cordoba Oro",
  "NPR - Nepalese Rupee",
  "OMR - Rial Omani",
  "PAB - Balboa",
  "PEN - Nuevo Sol",
  "PGK - Kina",
  "PHP - Philippine Peso",
  "PKR - Pakistan Rupee",
  "PLN - Zloty",
  "PYG - Guarani",
  "QAR - Qatari Rial",
  "RON - Romanian Leu",
  "RSD - Serbian Dinar",
  "RWF - Rwanda Franc",
  "SAR - Saudi Riyal",
  "SBD - Solomon Islands Dollar",
  "SCR - Seychelles Rupee",
  "SDG - Sudanese Pound",
  "SHP - Saint Helena Pound",
  "SLL - Leone",
  "SOS - Somali Shilling",
  "SRD - Surinam Dollar",
  "SSP - South Sudanese Pound",
  "STN - Dobra",
  "SVC - El Salvador Colon",
  "SYP - Syrian Pound",
  "SZL - Lilangeni",
  "THB - Baht",
  "TJS - Somoni",
  "TMT - Turkmenistan New Manat",
  "TND - Tunisian Dinar",
  "TOP - Pa’anga",
  "TTD - Trinidad and Tobago Dollar",
  "TWD - New Taiwan Dollar",
  "TZS - Tanzanian Shilling",
  "UAH - Hryvnia",
  "UGX - Uganda Shilling",
  "USN - US Dollar (Next day)",
  "UYI - Uruguay Peso en Unidades Indexadas (URUIURUI)",
  "UYU - Peso Uruguayo",
  "UZS - Uzbekistan Sum",
  "VEF - Bolivar",
  "VND - Dong",
  "VUV - Vatu",
  "WST - Tala",
  "XAF - CFA Franc BEAC",
  "XCD - East Caribbean Dollar",
  "XDR - SDR (Special Drawing Right)",
  "XOF - CFA Franc BCEAO",
  "XPF - CFP Franc",
  "XSU - Sucre",
  "XUA - ADB Unit of Account",
  "YER - Yemeni Rial",
  "ZMW - Zambian Kwacha",
  "ZWL - Zimbabwe Dollar",
]

export const SurveyContstants = {
  firm: {
    name: 1,
  },
}
