import { Auth } from "aws-amplify"
import { CognitoUser } from "@aws-amplify/auth"
import { CognitoUserSession } from "amazon-cognito-identity-js"
import { inspect } from "util"
import * as Sentry from "@sentry/react"
import { GraphQLRequest } from "@apollo/client"

export async function getToken(request: GraphQLRequest, headers: unknown) {
  let cognitoUserSession: CognitoUserSession

  try {
    cognitoUserSession = await Auth.currentSession()
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.addBreadcrumb({
        type: "debug",
        category: "headers",
        message: JSON.stringify(headers),
      })
      scope.setTag("operation", request.operationName)
      Sentry.captureException(
        new Error(`no current user continuing as unauthed user: ` + inspect(e)),
      )
    })
    return
  }
  const idToken = cognitoUserSession.getIdToken()

  if (idToken.payload.exp - new Date().getTime() < 1800) {
    console.log("refreshing token")
    const refreshToken = cognitoUserSession.getRefreshToken()
    const currentSession: CognitoUser = await Auth.currentAuthenticatedUser()

    const refreshedToken = await new Promise<string>((resolve, reject) =>
      currentSession.refreshSession(
        refreshToken,
        (err: unknown, data: CognitoUserSession) => {
          if (err) {
            const error = new Error(
              "Failed to refresh user session" + inspect(err),
            )

            Sentry.withScope((scope) => {
              scope.addBreadcrumb({
                type: "debug",
                category: "currentSession",
                data: currentSession,
              })
              Sentry.captureException(error)
            })
            Auth.signOut()
            reject(new Error("Failed to refresh user session"))
          }
          if (!data) {
            Sentry.withScope((scope) => {
              scope.addBreadcrumb({
                type: "debug",
                category: "currentSession",
                data: currentSession,
              })
              Sentry.captureException("refreshSession returned null")
            })
          }
          const jwt = data.getIdToken().getJwtToken()
          resolve(jwt)
        },
      ),
    )
    console.log("successfully refreshed token")
    return refreshedToken
  }
  const jwt = idToken.getJwtToken()
  return jwt
}
