import React from "react"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/styles"

import { ObjectFieldTemplateProps } from "@rjsf/core"
//import { utils } from "@rjsf/core"

//import AddButton from "@rjsf/material-ui/dist/AddButton"

//const { canExpand } = utils

const useStyles = makeStyles({
  root: {
    marginTop: 10,
  },
})

const ObjectInlineFieldTemplate = ({
  DescriptionField,
  description,
  TitleField,
  title,
  properties,
  required,
  disabled,
  readonly,
  uiSchema,
  idSchema,
  schema,
  formData,
  onAddClick,
}: ObjectFieldTemplateProps) => {
  const classes = useStyles()

  if (!properties) {
    console.log("No properties for:", schema)
    return null
  }

  return (
    <div className='object-inline-field-template'>
      {(uiSchema["ui:title"] || title) && (
        <TitleField
          id={`${idSchema.$id}-title`}
          title={title}
          required={required}
        />
      )}
      {description && (
        <DescriptionField
          id={`${idSchema.$id}-description`}
          description={description}
        />
      )}
      <Grid container={true} spacing={1} className={classes.root}>
        {properties.map((element: any, index: number) => (
          <Grid item xs key={index}>
            {element.content}
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default ObjectInlineFieldTemplate
