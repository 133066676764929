import React from "react"
import PropTypes from "prop-types"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"

interface UserTable {
  col0?: string
  col1?: string
  col2?: string
  col3?: string
  col4?: string
  allowMultiFirm?: boolean
}

export const UserTable: React.FC<UserTable> = ({
  col0,
  col1,
  col2,
  col3,
  col4,
  children,
  allowMultiFirm = false,
  ...rest
}) => {
  return (
    <Table>
      <colgroup>
        <col width='25%' />
        <col width='50%' />
        <col width='25%' />
      </colgroup>
      <TableHead>
        <TableRow>
          {
            col0 && (
              <TableCell align='left'>{col0}</TableCell>
            )
          }
          <TableCell align='left'>{col1}</TableCell>
          <TableCell align='left'>{col2}</TableCell>
          {col4 && <TableCell>{col4}</TableCell>}
          <TableCell align='right'>{col3}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  )
}

UserTable.defaultProps = {
  col1: "Asset Class",
  col2: "Investment Name",
  col3: "User Email",
}

UserTable.propTypes = {
  col1: PropTypes.string,
  col2: PropTypes.string,
  col3: PropTypes.string,
  col4: PropTypes.string,
}

export default UserTable
