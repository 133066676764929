import { gql } from "@apollo/client"

export const GET_LIST_SUBMITTED = gql`
  query GetListSubmitted(
    $page: PageInput
    $sort: [SubmittedSort!]
    $filter: SubmittedFilter
  ) {
    submittedList(page: $page, sort: $sort, filter: $filter) {
      items {
        id
        firmId
        userId
        email
        sourceId
        source {
          id
          name
        }
        userInput
        createdAt
        updatedAt
        updatedBy
        name
      }
      total
    }
  }
`

export const GET_FIRM = gql`
  query GetFirmByCurrentUser {
    getFirmByCurrentUser {
      id
      organizationId
      profileInput
      firmTypeId
      name
      size
      sizeSymbol
      url
      createdAt
      createdBy
      updatedBy
      updatedAt
    }
  }
`

export const ASSET_CLASSES = gql`
  query AssetClassList {
    assetClassEnumList {
      id
      name
    }
  }
`

export const CURRENT_USER = gql`
  query CurrentUserWithServiceProviderFirms(
    $page: PageInput
    $sort: [SubmittedSort!]
    $filter: SubmittedFilter
  ) {
    currentUser {
      id
      organizationId
      email
      organization {
        id
        name
        allowMultiFirm
        primaryFirm {
          id
          name
          profileInput
          firmTypeId
          size
          sizeSymbol
          url
          createdAt
          createdBy
          updatedBy
          updatedAt
          rcgFirmId
          submissionList(page: $page, sort: $sort, filter: $filter) {
            items {
              id
              firmId
              userId
              email
              sourceId
              source {
                id
                name
              }
              userInput
              createdAt
              updatedAt
              updatedBy
              name
            }
          }
        }
        serviceProviderFirms {
          id
          name
          profileInput
          firmTypeId
          size
          sizeSymbol
          url
          createdAt
          createdBy
          updatedBy
          updatedAt
          submissionList(page: $page, sort: $sort, filter: $filter) {
            items {
              id
              firmId
              userId
              email
              sourceId
              source {
                id
                name
              }
              userInput
              createdAt
              updatedAt
              updatedBy
              name
            }
          }
        }
      }
    }
  }
`
