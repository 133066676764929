import React, { useState, useCallback, useEffect, useRef } from "react"
import { Auth } from "aws-amplify"
import jwtDecode from "jwt-decode"
import * as Sentry from "@sentry/react"
import ReactGA from "react-ga"
import produce from "immer"
import {
  UserInput,
  FirmInput,
} from "graphql-types/generated/portal-client-types"
import { JSONSchema7Object } from "json-schema"

export type AuthState = {
  isLoading: boolean
  isAuthenticated: boolean
  token?: string | null
  email?: string | undefined
  userId: string | null
  isAdmin: boolean
  isROAdmin: boolean
  isCognitoInitialized: boolean
  isMachineUser: boolean
  registrationProps: RegistrationProps | null
}

interface SignOutOptions {
  global: boolean
}

// Fields collected during registration that are not stored in Cognito
export type RegistrationProps = {
  user: UserInput | null
  organization: FirmInput | null
}

export interface UserRegistrationInput {
  firstName?: string
  lastName?: string
  phoneNumber?: string
  email?: string
  streetAddress1?: string
  streetAddress2?: string
  city?: string
  state?: string
  zipCode?: string
  country?: string
  profileInput?: JSONSchema7Object
}

interface JWTClaims {
  aud: string
  auth_time: number
  email_verified: boolean
  email: string
  event_id: string
  exp: number
  iat: number
  iss: string
  sub: string
  token_use: string
  "cognito:username": string
  "cognito:groups"?: string[]
}

const defaultState: AuthState = {
  isLoading: false,
  isAuthenticated: false,
  token: null,
  email: undefined,
  userId: null,
  isAdmin: false,
  isROAdmin: false,
  isCognitoInitialized: false,
  registrationProps: null,
  isMachineUser: false,
}

export type AuthActions = {
  signIn: (
    email: string,
    regUser?: UserRegistrationInput,
    regOrganization?: FirmInput,
  ) => void
  signUp: (regUser: UserRegistrationInput, regOrganization: FirmInput) => void
  answerCustomChallenge: (answer: string) => void
  answerPasswordChallenge: (email: string, password: string) => void
  signOut: (options?: SignOutOptions) => void
  clearAuthContext: () => void
}

const defaultActions = {
  signIn: () => {},
  signUp: () => {},
  answerCustomChallenge: () => {},
  answerPasswordChallenge: () => {},
  signOut: () => {},
  clearAuthContext: () => {},
}

const AuthContext = React.createContext<[AuthState, AuthActions]>([
  defaultState,
  defaultActions,
])

const AuthProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<AuthState>(defaultState)
  const [cognitoUser, setCognitoUser] = useState<any>(null)
  const hasRenderedOnce = useRef<boolean>(false)

  // Allow certain useEffect to defer until after first render
  useEffect(() => {
    hasRenderedOnce.current = true
  }, [])

  useEffect(() => {
    const tryGetUserOnPageLoad = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        if (cognitoUser) {
          setCognitoUser(cognitoUser)
        }
      } catch (e) {
        // Not authenticated at time of page load
      }
    }
    tryGetUserOnPageLoad()
  }, [])

  const signIn = useCallback(
    async (
      email: string,
      regUser: UserInput | null = null,
      regOrganization: FirmInput | null = null,
    ) => {
      const user = await Auth.signIn(email)

      // If user/org exist, add to state
      // This looks redundant with signUp, but due to the asyncronous nature
      // ... of setState we need to add it in both calls
      // kludgey but effective
      const registrationProps = {
        user: regUser,
        organization: regOrganization,
      }

      const nextState = produce(state, (draftState) => {
        draftState.isLoading = true
        if (regUser) {
          draftState.registrationProps = registrationProps
        }
      })
      setState(nextState)
      setCognitoUser(user)
    },
    [state],
  )

  const clearAuthContext = () => {
    setState(defaultState)
    setCognitoUser(null)
  }

  const signOut = async (options?: SignOutOptions) => {
    await Auth.signOut(options)
    clearAuthContext()
  }

  const answerPasswordChallenge = useCallback(
    async (email: string, password: string) => {
      const user = await Auth.signIn(email, password)
      const nextState = produce(state, (draftState) => {
        draftState.isLoading = true
      })
      setState(nextState)
      setCognitoUser(user)
    },
    [cognitoUser, state],
  )

  const answerCustomChallenge = useCallback(
    async (answer: string) => {
      const user = await Auth.sendCustomChallengeAnswer(cognitoUser, answer)

      const nextState = produce(state, (draftState) => {
        draftState.isLoading = true
      })
      setState(nextState)
      setCognitoUser(user)
    },
    [state, cognitoUser],
  )

  const signUp = useCallback(
    async (regUser: UserRegistrationInput, regOrganization: FirmInput) => {
      const { email, firstName, lastName } = regUser
      if (!email) {
        return
      }

      const getRandomString = (bytes: number) => {
        const randomValues = new Uint8Array(bytes)
        window.crypto.getRandomValues(randomValues)
        return Array.from(randomValues).map(intToHex).join("")
      }

      const params = {
        username: email,
        password: getRandomString(30), // Never used
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
          "custom:organization_name": regOrganization.name,
        },
      }

      await Auth.signUp(params)
      const registrationProps = {
        user: regUser,
        organization: regOrganization,
      }
      const nextState = produce(state, (draftState) => {
        draftState.isLoading = true
        draftState.registrationProps = registrationProps
      })
      setState(nextState)
    },
    [state],
  )

  const intToHex = (nr: number) => {
    return nr.toString(16).padStart(2, "0")
  }

  // Whenever user changes, update all the state properties
  useEffect(() => {
    const onUserChange = async () => {
      try {
        const cognitoUserSession = await Auth.currentSession()
        const idToken = cognitoUserSession.getIdToken()
        const jwt = idToken.getJwtToken()
        const claims: JWTClaims = await jwtDecode(jwt)
        const groups = claims["cognito:groups"] || []

        const isAdmin = groups.length ? groups.includes("admin") : false
        const isROAdmin = groups.length
          ? groups.includes("admin-readonly")
          : false

        const isMachineUser = groups.length
          ? groups.includes("machine-user")
          : false
        const isAuthenticated = !!cognitoUser
        // If user exists, configure Sentry and GA
        if (cognitoUser) {
          const userId = cognitoUser.attributes?.sub
          if (userId) {
            Sentry.configureScope((scope) => {
              scope.setUser({
                id: userId,
                email: cognitoUser.attributes?.email,
              })
            })
            ReactGA.set({ userId })
            ReactGA.event({
              category: "User",
              action: "Signed in",
            })
          }
        }
        setState({
          ...state,
          isLoading: false,
          isAuthenticated,
          token: jwt,
          email: cognitoUser.username,
          userId: cognitoUser.attributes?.sub,
          isAdmin,
          isROAdmin,
          isCognitoInitialized: true,
          isMachineUser,
        })
      } catch (e) {
        setState({
          ...state,
          isLoading: false,
          isAuthenticated: false,
          token: null,
          email: undefined,
          userId: null,
          isAdmin: false,
          isROAdmin: false,
          isCognitoInitialized: true,
          isMachineUser: false,
        })
      }
    }
    if (hasRenderedOnce) {
      onUserChange()
    }
    // eslint-disable-next-line
  }, [cognitoUser])

  return (
    <AuthContext.Provider
      value={[
        state,
        {
          signIn,
          signUp,
          answerCustomChallenge,
          signOut,
          answerPasswordChallenge,
          clearAuthContext,
        },
      ]}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
