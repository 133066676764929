import React, { useCallback, useEffect, useState } from "react"
import { FieldProps } from "@rjsf/core"
import TitleField from "../TitleField"
import DescriptionField from "../DescriptionField"
import { Box } from "@material-ui/core"
import ReactDataSheet from "react-datasheet"
import "react-datasheet/lib/react-datasheet.css"
import { get, set, cloneDeep } from "lodash"
import { getSchemaWithDefinitions } from "../rsjfUtils"
import { ColumnDefinition } from "./ColumnDefinitions"
import { getCellValue } from "./GridUtils"

export const ExposureGrid = (props: FieldProps) => {
  const { schema, formData, uiSchema, onChange, registry } = props
  const [grid, setGrid] = useState<any[][] | null>(null)
  const [columns, setColumns] = useState<ColumnDefinition[]>([])

  // Adds a "sum" row which sums up all previous columns
  const addSumRow = useCallback((gridData: any[][]) => {
    const sumRow: any[] = [{ value: "Sum", readOnly: true }]

    if (!gridData || !gridData.length || !gridData[0].length) {
      return
    }

    for (let row = 0; row < gridData.length; row++) {
      for (let col = 1; col < gridData[row].length; col++) {
        if (!sumRow[col]) {
          sumRow[col] = {
            readOnly: true,
            value: 0,
            className: "text-align-right",
          }
        }
        const rawValue = gridData[row][col] ? gridData[row][col].value : null
        if (rawValue) {
          const numericValue = Number(rawValue)
          if (numericValue !== Number.NaN) {
            sumRow[col].value += numericValue
          }
        }
      }
    }

    gridData.push(sumRow)
  }, [])

  // Adds a "sum" row which sums up all previous columns
  const addSumColumn = useCallback(
    (gridData: any[][], nextColumns: ColumnDefinition[]) => {
      nextColumns.push({ title: "Total", propName: "" })

      if (!gridData || !gridData.length || !gridData[0].length) {
        return
      }

      for (let row = 0; row < gridData.length; row++) {
        let sumCell = {
          readOnly: true,
          value: 0,
        }
        for (let col = 1; col < gridData[row].length; col++) {
          const rawValue = gridData[row][col] ? gridData[row][col].value : null
          if (rawValue) {
            const numericValue = Number(rawValue)
            sumCell.value += numericValue
          }
        }
        gridData[row].push(sumCell)
      }
    },
    [],
  )

  const handleChange = useCallback(
    (changes: any) => {
      let nextFormData = cloneDeep(formData)

      changes.forEach(({ cell, row, col, value }: any) => {
        const cellValue = getCellValue(value)
        set(nextFormData, cell.key, cellValue)
      })

      onChange(nextFormData)
    },
    [formData, onChange],
  )

  // Creates 2D array from schema
  // ...where the top-level props are rows
  // ...and the child properties represent columns
  useEffect(() => {
    let nextColumns: ColumnDefinition[] = [{ title: "", propName: "" }] // First column is category
    let nextGrid: any[] = []
    const registryAny: any = registry
    const rootSchema = registryAny.rootSchema
    const uiSchemaOptions = uiSchema?.["ui:options"] || {}

    const schemaWithDefinitions = getSchemaWithDefinitions(schema, rootSchema)

    if (!schemaWithDefinitions.properties) {
      return
    }

    // Each parent prop represents a row
    Object.entries(schemaWithDefinitions.properties).forEach(
      (parentEntry: any, rowIx) => {
        const [parentKey, parentValue] = parentEntry

        nextGrid[rowIx] = [{ readOnly: true, value: parentValue.title }]

        // This should never happen, but fail gracefully if no child properties exist
        if (!parentValue.properties) {
          console.warn(
            `No parentValue.properties found for ${JSON.stringify(
              parentValue,
            )}`,
          )
          return
        }

        // Map child properties to columns
        Object.entries(parentValue.properties).forEach(
          (childEntry: any, ix) => {
            const [childKey, childValue] = childEntry
            let colIx = ix + 1

            // On first "row" build column definitions
            if (rowIx === 0) {
              nextColumns.push({ title: childValue.title, propName: childKey })
            }

            const key = `${parentKey}.${childKey}`
            const value = get(formData, key, "")
            nextGrid[rowIx][colIx] = { key, value }
          },
        )
      },
    )

    // Add sum row/column based on ui:options
    const { hasSumColumn } = uiSchemaOptions
    if (hasSumColumn) {
      addSumColumn(nextGrid, nextColumns)
    } else {
      addSumRow(nextGrid)
    }

    setColumns(nextColumns)
    setGrid(nextGrid)
  }, [schema, formData, registry, uiSchema, addSumColumn, addSumRow])

  if (!grid) {
    return null
  }

  return (
    <div className='exposure-grid'>
      <TitleField title={schema.title} />
      {schema.description && (
        <DescriptionField description={schema.description} />
      )}
      <Box style={{ marginTop: "1em" }}>
        <ReactDataSheet
          data={grid}
          // https://github.com/nadbm/react-datasheet/issues/309
          parsePaste={(pastedString) => 
            pastedString.trim().split(/\r?\n/).map(row => row.split('\t'))
          }
          valueRenderer={(cell: any) => cell.value}
          onCellsChanged={handleChange}
          sheetRenderer={(props: any) => (
            <table className={props.className}>
              <thead>
                <tr>
                  {columns.map((col, ix) => (
                    <th key={ix} className='cell read-only'>
                      {col.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{props.children}</tbody>
            </table>
          )}
        />
      </Box>
    </div>
  )
}

export default ExposureGrid
