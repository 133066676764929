import React from "react"
import {
  TextField as MTextField,
  styled,
  TextFieldProps,
  InputAdornment,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { kebabCase } from 'lodash';

const TextField: React.FC<TextFieldProps> = ({ children, ...props }) => {
  const { label } = props; // not destructuring earlier 'cause need to pass it to MTextField too
  return (
    <MTextField
      data-cy={`search-input-${kebabCase(String(label))}`}
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    >
      {children}
    </MTextField>
  )
}

export default styled(TextField)({
  marginTop: "2em",
  marginBottom: "1em",
})
