import React, { useMemo, useContext } from "react"
import Form from "../CustomForm"
import { JSONSchema7 } from "json-schema"
import {
  UiSchema,
  IChangeEvent,
  ISubmitEvent,
  ErrorSchema,
  ErrorListProps,
  Field,
} from "@rjsf/core"
import { Button } from "../Button"
import * as Sentry from "@sentry/react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { AuthContext } from "../../context/AuthContext"
import ArrayFieldTemplate from "../ArrayFieldTemplate"
import ErrorListTemplate from "./ErrorListTemplate"
import DefaultFieldTemplate from "../../inputs/FieldTemplate/DefaultFieldTemplate"
import CustomSelectWidget from "../../inputs/SelectWidget/SelectWidget"
import FormWithoutEnter from "../FormWithoutEnter"
import CheckboxWidget from "../../inputs/CheckboxWidget"
import DescriptionField from "../../inputs/DescriptionField"
import MinDateWidget from "../../inputs/CustomDateInput"
import CustomNumberField from "../../inputs/NumberField"

// Extension of RJSF Form component, pre-loaded with common defaults

export type ImpactFormProps = {
  schema: JSONSchema7
  uiSchema: UiSchema
  onChange: (e: IChangeEvent<any>, es?: ErrorSchema) => any
  onSubmit: (e: ISubmitEvent<any>) => any
  formData: any
  idPrefix: string
  submittedId: string // Either firm id or submitted id (uuid)
  formTemplateId: number
}

// Data that is accessible to child components w/in Form
// https://react-jsonschema-form.readthedocs.io/en/latest/advanced-customization/custom-widgets-fields/#the-formcontext-object
export type ImpactFormContext = {
  submittedId: string
  formTemplateId: number
}

const customFields = {
  DescriptionField,
  NumberField: CustomNumberField as Field,
}

const customWidgets = {
  CheckboxWidget,
  SelectWidget: CustomSelectWidget,
  DateWidget: MinDateWidget,
}

export const ImpactForm = (props: ImpactFormProps) => {
  const {
    schema,
    uiSchema,
    onChange,
    onSubmit,
    formData,
    idPrefix,
    submittedId,
    formTemplateId,
  } = props

  const [authContextState] = useContext(AuthContext)

  const { isROAdmin } = authContextState
  const formContext: ImpactFormContext = useMemo(() => {
    return { submittedId, formTemplateId }
  }, [submittedId, formTemplateId])

  return (
    <div className='impact-profile'>
      <Form
        FieldTemplate={DefaultFieldTemplate}
        ArrayFieldTemplate={ArrayFieldTemplate}
        fields={customFields}
        disabled={isROAdmin}
        widgets={customWidgets}
        noValidate={false}
        tagName={FormWithoutEnter}
        ErrorList={ErrorListTemplate}
        schema={schema}
        uiSchema={uiSchema}
        onChange={onChange}
        onSubmit={onSubmit}
        formData={formData}
        idPrefix={idPrefix}
        formContext={formContext}
      >
        <AppBar
          position='sticky'
          color='primary'
          style={{
            top: "auto",
            bottom: 0,
            marginTop: "5em",
          }}
        >
          <Toolbar>
            <Button
              variant='contained'
              onClick={() => {
                Sentry.captureMessage("Submit button clicked")
              }}
              data-id='impactForm__savebutton'
              color='secondary'
              style={{ marginLeft: "auto" }}
              type='submit'
              {...(authContextState.isROAdmin && {
                disabled: true,
              })}
            >
              Save
            </Button>
          </Toolbar>
        </AppBar>
      </Form>
    </div>
  )
}

export default ImpactForm
