import React, { useEffect, useState, useMemo } from "react"
import { FieldProps } from "@rjsf/core"
import TitleField from "../TitleField"
import DescriptionField from "../DescriptionField"
import { Box } from "@material-ui/core"
import ReactDataSheet from "react-datasheet"
import "react-datasheet/lib/react-datasheet.css"
import { get, set, cloneDeep, isArray, isObject } from "lodash"
import { getSchemaWithDefinitions } from "../rsjfUtils"
import { ColumnDefinition } from "./ColumnDefinitions"
import { getCellValue } from "./GridUtils"

interface Row {
  [key: string]: number | string | null | undefined
}
interface Grid {
  [key: string]: Row
}

// empty objects will throw validation errors
// if a user removes all the counts for a category we want to remove the category key
function trimEmptyObjects(grid?: Grid) {
  if (!grid) return grid
  return Object.keys(grid).reduce((accu: Grid, curr: string) => {
    const row = grid[curr as keyof Grid]
    if (!row) return accu
    if (Object.values(row).every((val) => val === null || val === undefined)) {
      return accu
    }
    return {
      [curr]: grid[curr as keyof Grid],
      ...accu,
    }
  }, {})
}

// Base component for XLS-style grid input
// Creates 2D array from schema
// ...where the top-level props are rows
// ...and the child properties represent columns

export const XlsGrid = (props: FieldProps) => {
  const { schema, uiSchema, formData, onChange, registry } = props
  const [columns, setColumns] = useState<ColumnDefinition[]>([])
  const [grid, setGrid] = useState<any[][] | null>(null)
  const registryAny: any = registry
  const rootSchema = registryAny.rootSchema
  const uiSchemaOptions = uiSchema?.["ui:options"] || {}
  const { titleColumnWidth, hasSumColumn, showTitleInGrid } = uiSchemaOptions

  // Get formData or use defulat {}
  const gridFormData = useMemo(() => {
    if (formData && isObject(formData) && !isArray(formData)) {
      return formData
    }
    return {}
  }, [formData])

  useEffect(() => {
    let nextColumns: ColumnDefinition[] = [
      {
        title: showTitleInGrid ? schema.title || "" : "",
        propName: "",
        width: titleColumnWidth ? `${titleColumnWidth}px` : "inherit",
      },
    ] // First column is category
    let nextGrid: any[] = []

    const schemaWithDefinitions = getSchemaWithDefinitions(schema, rootSchema)

    if (!schemaWithDefinitions.properties) {
      return
    }

    // Each parent prop represents a row
    Object.entries(schemaWithDefinitions.properties).forEach(
      (parentEntry: any, rowIx) => {
        const [parentKey, parentValue] = parentEntry

        nextGrid[rowIx] = [
          {
            readOnly: true,
            value: parentValue.title,
            width: titleColumnWidth ? titleColumnWidth : "inherit",
          },
        ]

        // This should never happen, but fail gracefully if no child properties exist
        if (!parentValue.properties) {
          console.warn(
            `No parentValue.properties found for ${JSON.stringify(
              parentValue,
            )}`,
          )
          return
        }

        // Map child properties to columns
        Object.entries(parentValue.properties).forEach(
          (childEntry: any, ix) => {
            const [childKey, childValue] = childEntry
            let colIx = ix + 1

            // On first "row" build column definitions
            if (rowIx === 0) {
              nextColumns.push({
                title: childValue.title,
                propName: childKey,
                width: "inherit",
                description: childValue.description,
              })
            }

            const key = `${parentKey}.${childKey}`
            const value = get(gridFormData, key, "")
            nextGrid[rowIx][colIx] = { key, value }
          },
        )
      },
    )

    // Add sum row/column based on ui:options
    addSumRow(nextGrid)
    if (hasSumColumn) {
      addSumColumn(nextGrid, nextColumns)
    }

    setColumns(nextColumns)
    setGrid(nextGrid)
  }, [
    schema,
    gridFormData,
    hasSumColumn,
    rootSchema,
    showTitleInGrid,
    titleColumnWidth,
  ])

  // Adds a "sum" row which sums up all previous columns
  const addSumRow = (gridData: any[][]) => {
    const sumRow: any[] = [{ value: "Total", readOnly: true }]

    if (!gridData || !gridData.length || !gridData[0].length) {
      return
    }

    for (let row = 0; row < gridData.length; row++) {
      for (let col = 1; col < gridData[row].length; col++) {
        if (!sumRow[col]) {
          sumRow[col] = {
            readOnly: true,
            value: 0,
          }
        }
        const rawValue = gridData[row][col] ? gridData[row][col].value : null
        if (rawValue) {
          const numericValue = Number(rawValue)
          if (numericValue !== Number.NaN) {
            sumRow[col].value += numericValue
          }
        }
      }
    }

    gridData.push(sumRow)
  }

  // Adds a "sum" row which sums up all previous columns
  const addSumColumn = (gridData: any[][], nextColumns: ColumnDefinition[]) => {
    nextColumns.push({ title: "Total", propName: "" })

    if (!gridData || !gridData.length || !gridData[0].length) {
      return
    }

    for (let row = 0; row < gridData.length; row++) {
      let sumCell = {
        readOnly: true,
        value: 0,
      }
      for (let col = 1; col < gridData[row].length; col++) {
        const rawValue = gridData[row][col] ? gridData[row][col].value : null
        if (rawValue) {
          const numericValue = Number(rawValue)
          sumCell.value += numericValue
        }
      }
      gridData[row].push(sumCell)
    }
  }

  const handleChange = (changes: any) => {
    let nextFormData = cloneDeep(gridFormData)

    changes.forEach(({ cell, row, col, value }: any) => {
      const cellValue = getCellValue(value)
      set(nextFormData, cell.key, cellValue)
    })

    nextFormData = trimEmptyObjects(nextFormData as Grid) as Grid
    onChange(nextFormData)
  }

  if (!grid) {
    return null
  }

  return (
    <div className='xls-grid'>
      {schema.title && !showTitleInGrid && <TitleField title={schema.title} />}
      {schema.description && (
        <DescriptionField description={schema.description} />
      )}
      <Box
        style={{
          marginTop: "1em",
          maxWidth: columns.length < 3 ? "600px" : "inherit",
        }}
      >
        <ReactDataSheet
          data={grid}
          // https://github.com/nadbm/react-datasheet/issues/309
          parsePaste={(pastedString) => 
            pastedString.trim().split(/\r?\n/).map(row => row.split('\t'))
          }
          valueRenderer={(cell: any) => cell.value}
          onCellsChanged={handleChange}
          sheetRenderer={(props: any) => (
            <table className={props.className}>
              <thead>
                <tr>
                  {columns.map((col, ix) => (
                    <th
                      key={ix}
                      className='cell read-only rotate'
                      style={{ width: col.width }}
                    >
                      <div title={col.description}>
                        <span style={{ overflowWrap: "break-word" }}>
                          {col.title}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{props.children}</tbody>
            </table>
          )}
        />
      </Box>
    </div>
  )
}

export default XlsGrid
