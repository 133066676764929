import React from "react"
import {
  Link as MLink,
  styled,
  LinkProps as MLinkProps,
} from "@material-ui/core"

interface LinkProps extends MLinkProps {
  style?: any
}

const Link: React.FC<LinkProps> = ({ children, ...props }) => {
  return <MLink {...props}>{children}</MLink>
}

export default styled(Link)({
  marginLeft: "1rem",
})
