import React from "react"
import { Typography, Box } from "@material-ui/core"
import { FieldProps } from "@rjsf/core"
//import { makeStyles } from "@material-ui/core/styles"

// Simple section header for RSJF custom field

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: "20px 0 30px 0",
//   },
// }))

export const SectionHeader = ({ schema, idSchema }: FieldProps) => {
  //const classes = useStyles()
  const { title } = schema
  const { $id } = idSchema

  return (
    <div className='section-header' id={$id}>
      <Box bgcolor='primary.main' color='primary.contrastText' p={1}>
        <Typography variant='h5' component='h5'>
          {title}
        </Typography>
      </Box>
    </div>
  )
}

export default SectionHeader
