import React from "react"
import { FieldProps } from "@rjsf/core"
import { isNaN, merge } from "lodash"
import { ColumnDefinition } from "../grids/ColumnDefinitions"
import numeral from "numeral"
import BaseAumTable from "./BaseAumTable"

// Asset AUM grid

const columns: ColumnDefinition[] = [
  {
    title: "Year",
    propName: "year",
    readOnly: true,
    width: "100px",
    type: "number",
  },
  {
    title: "Quarter",
    propName: "quarter",
    readOnly: true,
    width: "100px",
    type: "string",
  },
  {
    title: "Fund AUM",
    propName: "fundAum",
    type: "number",
    valueRenderer: (cell) => {
      const value = Number(cell.value)
      return isNaN(value) || !value ? "" : numeral(value).format("0,0")
    },
  },
  {
    title: "Strategy AUM",
    propName: "strategyAum",
    type: "number",
    valueRenderer: (cell) => {
      const value = Number(cell.value)
      return isNaN(value) || !value ? "" : numeral(value).format("0,0")
    },
  },
  {
    title: "Net Fund Flow",
    propName: "fundFlow",
    type: "number",
    valueRenderer: (cell) => {
      const value = parseFloat(cell.value)
      return isNaN(value) ? "" : numeral(value).format("0,0")
    },
  },
  {
    title: "Net Strategy Flow",
    propName: "strategyFlow",
    type: "number",
    valueRenderer: (cell) => {
      const value = parseFloat(cell.value)
      return isNaN(value) ? "" : numeral(value).format("0,0")
    },
  },
]

export const AUMTable = (props: FieldProps) => {
  const uiSchema = merge(props.uiSchema, {
    "ui:options": {
      hideTitle: true,
      showCurrency: true,
    },
  })

  return (
    <BaseAumTable
      {...props}
      uiSchema={uiSchema}
      className={`asset-aum`}
      columns={columns}
      maxGridWidth='800px'
    />
  )
}

export default AUMTable
