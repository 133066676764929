import { Firm, Organization } from "graphql-types/generated/portal-client-types"
import React from "react"
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  Button,
  TableHead,
  Divider,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useHistory } from "react-router-dom"
import { kebabCase } from "lodash"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  firmTableCell: {
    border: "none",
  },
}))

export function MultiFirmTable({
  organization,
}: {
  organization: Organization
}) {
  return (
    <>
      <SingleFirmTable organization={organization} />

      <Divider />
      {organization.serviceProviderFirms.length > 0 ? (
        <Table
          data-cy='userforms__table-serviceProviderFirms'
          className='userforms multifirm'
        >
          <colgroup>
            <col width='25%' />
            <col width='35%' />
            <col width='20%' />
            <col width='20%' />
          </colgroup>
          <TableBody>
            {organization.serviceProviderFirms.map((firm) => (
              <FirmRow key={firm.id} firm={firm} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Alert severity='info'>
          No additional firms are linked to organization {organization.name}
        </Alert>
      )}
    </>
  )
}

export function SingleFirmTable({
  organization,
}: {
  organization: Organization
}) {
  if (!organization.primaryFirm) {
    return <Alert severity='info'>No firm registered.</Alert>
  }
  return (
    <Table data-cy='userforms__table-primaryFirm' className='userforms'>
      <colgroup>
        <col width='25%' />
        <col width='35%' />
        <col width='20%' />
        <col width='20%' />
      </colgroup>
      <TableBody>
        <FirmRow firm={organization.primaryFirm} />
      </TableBody>
    </Table>
  )
}

export function FirmRow({ firm }: { firm: Firm }) {
  const history = useHistory()
  const classNames = useStyles()

  function handleFirmClick(firmId: string) {
    firmId && history.push(`/firm/${firmId}`)
  }

  function handleAddInvestmentClick(firmId: string) {
    firmId && history.push(`/fill-form/${firmId}`)
  }

  return (
    <TableRow data-cy='firm-row' className='table-row'>
      <TableCell
        component='th'
        scope='row'
        data-cy={`firm-name-${kebabCase(firm.name ?? "")}`}
        className={classNames.firmTableCell}
      >
        <>{firm.name}</>
      </TableCell>
      <TableCell
        component='th'
        scope='row'
        align='left'
        className={classNames.firmTableCell}
      >
        <Button
          variant='outlined'
          data-cy={`firm-profile-button-${kebabCase(firm.name ?? "")}`}
          color='primary'
          onClick={() => handleFirmClick(firm.id)}
          name='view-firm-profile'
          fullWidth
        >
          Firm Profile
        </Button>
      </TableCell>
      <TableCell
        component='th'
        scope='row'
        align='right'
        className={classNames.firmTableCell}
      >
        {firm.updatedBy ?? "Unavailable"}
      </TableCell>
      <TableCell
        component='th'
        scope='row'
        align='center'
        className={classNames.firmTableCell}
      >
        <Button
          variant='outlined'
          data-cy={`add-submission-${kebabCase(firm.name ?? "")}`}
          color='primary'
          onClick={() => handleAddInvestmentClick(firm.id)}
          name='add-submission'
          fullWidth
        >
          Add Investment
        </Button>
      </TableCell>
    </TableRow>
  )
}
