import React from "react"
import { Paper as MPaper } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const usePaperStyles = makeStyles((theme) => ({
  root: { margin: "1em 0", padding: "1em" },
}))

interface IProps {
  children?: React.ReactNode
}

export const Paper: React.FC<IProps> = ({ children, ...rest }) => {
  let styles = usePaperStyles()
  return (
    <MPaper className={styles.root} {...rest}>
      {children}
    </MPaper>
  )
}

export default Paper
