import React, { useMemo } from "react"
import { FieldProps } from "@rjsf/core"
import {
  ColumnDefinition,
  mapDefinitionToColumn,
  valuationColumns,
} from "../grids/ColumnDefinitions"
import BaseAumTable from "./BaseAumTable"
import { getDefinitionName } from "../rsjfUtils"

// Gerneralized AUM grid, where column definitions are inferred
// ...based on schema.definition

// These should never be used, and will trigger a warning
const defaultColumns: ColumnDefinition[] = [
  {
    title: "Year",
    propName: "year",
    readOnly: true,
    width: "100px",
    type: "number",
  },
  {
    title: "Quarter",
    propName: "quarter",
    readOnly: true,
    width: "100px",
    type: "string",
  },
]

export const ValuationsTable = (props: FieldProps) => {
  const { schema } = props

  return (
    <BaseAumTable
      {...props}
      className={`asset-aum`}
      columns={valuationColumns}
      maxGridWidth='800px'
    />
  )
}

export default ValuationsTable
