import numeral from "numeral"

export type ColumnDefinition = {
  propName: string
  title: string
  description?: string
  width?: string
  readOnly?: boolean
  type?: "number" | "string"
  valueRenderer?: (cell: any, rowIx: number, colIx: number) => any
}

const floatRenderer = (cell: any) => {
  const value = parseFloat(cell.value)
  return isNaN(value) ? "" : numeral(value).format("0.00")
}

export const performanceColumns: ColumnDefinition[] = [
  { title: "Year", propName: "year", readOnly: true },
  {
    title: "Jan",
    propName: "jan",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "Feb",
    propName: "feb",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "Mar",
    propName: "mar",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "Apr",
    propName: "apr",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "May",
    propName: "may",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "Jun",
    propName: "jun",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "Jul",
    propName: "jul",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "Aug",
    propName: "aug",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "Sep",
    propName: "sep",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "Oct",
    propName: "oct",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "Nov",
    propName: "nov",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "Dec",
    propName: "dec",
    valueRenderer: floatRenderer,
    type: "number",
  },
  {
    title: "YTD",
    propName: "ytd",
    readOnly: true,
    valueRenderer: floatRenderer,
  },
]

export const valuationColumns: ColumnDefinition[] = [
  {
    title: "Year",
    propName: "year",
    readOnly: true,
    width: "100px",
    type: "number",
  },
  {
    title: "Quarter",
    propName: "quarter",
    readOnly: true,
    width: "100px",
    type: "string",
  },
  {
    title: "Valuation",
    propName: "valuation",
    type: "number",
    valueRenderer: (cell) => {
      const value = Number(cell.value)
      return isNaN(value) || !value ? "" : numeral(value).format("0.000")
    },
  },
]

export const mapDefinitionToColumn = (
  definition: string,
): ColumnDefinition[] | null => {
  switch (definition) {
    case "Performance":
      return performanceColumns
    case "Valuation":
      return valuationColumns
    case "ValuationRow":
      return valuationColumns
    default:
      return valuationColumns
  }
}
