import { identity } from 'lodash';

export function toHash<T>(input: T[], keyPicker: (value: T) => string = identity): Hash<T> {

  if (input === undefined || input === null) {
    return {};
  }

  if (!Array.isArray(input)) {
    throw new Error(`Cannot convert non-array to hash: ${ String(input).substr(0, 25) }`);
  }

  return input.reduce(
    (acc: Hash<T>, curr: T) => {
      acc[ keyPicker(curr) ] = curr;
      return acc;
    },
    {});
}
